import "components/Button/Button.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"components/Button/Button.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VV227jIBB971cgVX10hZ3Ejt2PqbDBzuwSsDBumq723xeMLxB7pbRq46ccDnNmzgzw/Bq/VdkVY4z+PCBUSS5VgR7r4fdikFoKHXXwwQoU79t3C3EQLDoxaE7agBg/zbzLCGYYW6wk1e9GyV5QExLXZV7mFj6DiC5A9cmGxC5mDZwHui2hFERTIIySUXeSnPaUUlFmssXLn0gRCn1nOI5S9aqzBbUShGbKQhS6lpNrgUAMhdScDVTCoRERaHY22ys20X/1nYb6GlWmPgP6Sw1pC3S0Qn8fnmcfCyNASs6oM3RMQEgdEc7lhdGVMyy138u2/aEvnlA8xA8CZeyY5XnAStYsRtMKJwFrt2YldHfY7wLWfs36xJxMHRlLnOLPJS7A1Ne4fUed5EAXi7xs0iEbtxnEiSnQwXo2rC9jdGu7VkR0LVGmnX5ek4fBmJJeSy/VDZ+PX1PbPBQ3ahPHVwuH7A6hZcbGqBuO5t/Z3zgps+zgCS7Aff0l35kNYyndY7/8Gbgvm3LjHI0hPFblWJtX0VrHb38YOatLV+ftnHha9GsTtxyz/0/cxtmnPzJxzNUgO9AghcmjNM70mtldynVy7JHW8lyg3XTzbz8ssxnGcpT6ts/NSMK52J6okeQgfeXM3t/qTPjqoTu4h+7mRRyehH+dAJXfWgcAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var black = '_1vc7y003';
export var blackLink = '_1vc7y00d';
export var caution = '_1vc7y002';
export var deleteBtn = '_1vc7y00a';
export var deleteFillBtn = '_1vc7y00b';
export var icon = '_1vc7y006';
export var link = '_1vc7y007';
export var primaryFade = '_1vc7y00c';
export var primaryLink = '_1vc7y008';
export var rightIcon = '_1vc7y005';
export var root = '_1vc7y000';
export var secondary = '_1vc7y001';
export var success = '_1vc7y009';
export var tag = '_1vc7y00e';
export var white = '_1vc7y004';