import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { useDocumentContext } from "./DocumentContext";
import { useProjectContext } from "./ProjectContext";

import { TEvidence } from "@web/types/ai";
import { TDBMetaBase } from "@web/types/api";
import { TChecklist, TSelectCheclistBase } from "@web/types/template";
import { EUserRoles } from "@web/types/user";

type TC = {
  checklist?: TChecklist;
  canEditComplete: boolean;
  isEditable: boolean | undefined;
  evidenceToScrollTo: TEvidence | null;
  setChecklist: Dispatch<SetStateAction<Readonly<TSelectCheclistBase & TDBMetaBase> | undefined>>;
  setEvidenceToScrollTo: (evidence: TEvidence) => void;
};

type TChecklistStatus = "IN_PROGRESS" | "COMPLETED" | "SENIOR_REVIEW";

export const ChecklistContext = createContext<TC>({
  checklist: undefined,
  canEditComplete: false,
  isEditable: true,
  evidenceToScrollTo: null,
  setChecklist: () => null,
  setEvidenceToScrollTo: () => null,
});

export const useChecklistContext = (): TC => {
  return useContext(ChecklistContext);
};

export const ChecklistContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { documentChecklists } = useDocumentContext();
  const router = useRouter();
  const { data: userData } = useSession();
  const { getUserRole } = useProjectContext();

  const [checklist, setChecklist] = useState<TChecklist>();
  const [status, setStatus] = useState<TChecklistStatus>();
  const [evidenceToScrollTo, setEvidenceToScrollTo] = useState<TEvidence | null>(null);

  const userProjectRole = getUserRole();
  const checklistId = router.query.checklistid as string;
  const userId = userData?.user?.id;

  const isChecklistCreator = checklist && checklist.createdBy === userId;
  const inSeniorReviewer = checklist && checklist?.seniorReviewer === userId;
  const isEditable =
    (isChecklistCreator && !(status == "SENIOR_REVIEW")) || (status == "SENIOR_REVIEW" && inSeniorReviewer);

  const canEditComplete =
    status === "COMPLETED" && (isChecklistCreator || userProjectRole === EUserRoles.PROJECT_COORDINATOR);

  useEffect(() => {
    if (checklist) {
      checklist.isDraft && !checklist.seniorReviewer && setStatus("IN_PROGRESS");
      checklist.seniorReviewer && checklist.isDraft && setStatus("SENIOR_REVIEW");
      !checklist.isDraft && setStatus("COMPLETED");
    }
  }, [checklist]);

  useEffect(() => {
    const checklist = documentChecklists.find((checklist: TChecklist) => checklist.id === checklistId);

    setChecklist(checklist);
  }, [documentChecklists, checklistId]);

  return (
    <ChecklistContext.Provider
      value={{ checklist, setChecklist, canEditComplete, isEditable, evidenceToScrollTo, setEvidenceToScrollTo }}
    >
      {children}
    </ChecklistContext.Provider>
  );
};
