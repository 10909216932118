import "components/NotificationList/NotificationList.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"components/NotificationList/NotificationList.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VVa4+cIBT9vr+CpNlkm5YJvmfcX4OCSotgEHfcbfrfy8NR2Zl9tDVGk8PlcDj3Xjg05wn/KBD4dQfAIEemmRQlwNUo+aTpo0G1HEpQY14/RAjdg2/gOMxf7cCACWGiLUGcDjNAw2xBxdpO7+MhiC8TKlz/bJWcBIG15FKV4EvjHjcoFaEGigyXWZwRM5g0WZP5wRmOHSbybAJyE+E+SWY+qq3wA/oOlveATm6tywIxSbI0sUgjhYZn6vXlCK3YyF6ooU39Bl4gE4TOJcg2VVBhwqaxBEuMfKKq4VZMxwihwmJnRnRnIorYxxA2Dhw/l6Dh1AH2DwlTtPYeG4VTb6b+vjv4JEQuCW8I9yLjRYCH9DM3mJCqx/xqh5XkxIKcCQq7BYwSdB+kzmRtIV1lxIGMgh6L0+kNr/5ZRu7n91i1TMBKai370hbWR+ISJ67H88oVZyhMCjSe40nLi+dmuR1B6giukoM5awVkmvYmyzUVmioLt3jYTP9AbRSvco19kxqtf4NknmsTUHZWqZOxNUS5VfsambkYTWcNnby9sK33rEnoyvbsur7zwMjckS9Fu3r4H1kPE5z5Ogs0pVbTKqBwAm7Wyq1z4rU5OahutsuOP9jptTtr5PHvSyKKAzNPezPzT3mJPt1BSypDf8OuwNsWF/ooKFl3hCfBFO/e+4duUJ3rQX2rDVba+hNeuoNw1Fjpx+DSUZRjzZ7ojo683lhchOZSmpMUhTz7y2u5juKlWS+il/z9AVDJ16T+BgAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var avatarContainer = 'fwuaj7c';
export var count = 'fwuaj72';
export var dateContainer = 'fwuaj79';
export var divider = 'fwuaj7b';
export var header = 'fwuaj71';
export var noNotification = 'fwuaj75';
export var notification = 'fwuaj74';
export var notificationContainer = 'fwuaj70';
export var notificationContent = 'fwuaj76';
export var notificationList = 'fwuaj73';
export var redMark = 'fwuaj7d';
export var time = 'fwuaj7a';
export var unreadNotification = 'fwuaj77';
export var userContainer = 'fwuaj78';