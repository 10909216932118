import React from "react";

import Button, { ButtonVariant } from "../Button/Button";
import Icon, { IconName } from "../Icon/Icon";

import * as styles from "./FormSuccess.css";

import { dashboard, fade, ui as uiColor } from "@web/styles/palette.css";

export type Variant = "success" | "caution" | "action" | "green" | "blue";

export type TFormSuccessProps = {
  buttonLabel: string;
  onClick: () => void;
  icon?: IconName;
  variant?: Variant;
  title: string;
  content: string | React.ReactNode;
  buttonVariant?: ButtonVariant;
  cancelButton?: boolean;
  cancelHandler?: () => void;
  cancelButtonLabel?: string;
};

function FormSuccess({
  icon = "check-mark",
  title,
  content,
  buttonLabel,
  onClick,
  variant = "success",
  buttonVariant = "black",
  cancelButton,
  cancelHandler,
  cancelButtonLabel = "Cancel",
}: TFormSuccessProps) {
  const colors: Record<Variant, { iconColor: string; bgColor: string }> = {
    success: { iconColor: dashboard?.blue, bgColor: fade?.primary },
    caution: { iconColor: dashboard?.red, bgColor: fade?.red },
    action: { iconColor: dashboard?.red, bgColor: fade?.yellow },
    green: {
      iconColor: uiColor?.green,
      bgColor: fade?.green,
    },
    blue: {
      iconColor: dashboard?.darkNavy,
      bgColor: fade?.secondary,
    },
  };

  const ui = colors[variant];
  return (
    <div className={styles.root}>
      <div className={styles.image} style={{ background: ui.bgColor }}>
        <Icon name="star" size={70} color={ui.iconColor} className={styles.star} />
        <Icon name={icon} size={32} color={ui.iconColor} />
      </div>
      <h4 className={styles.successTitle}>{title}</h4>
      <div className={styles.successDesc}>{content}</div>
      {cancelButton && (
        <Button label={cancelButtonLabel} variant="white" onClick={cancelHandler} className={styles.cancelButton} />
      )}
      {buttonLabel && <Button label={buttonLabel} variant={buttonVariant} onClick={onClick} />}
    </div>
  );
}

export default FormSuccess;
