import { useState } from "react";

function useModal(initialValue?: boolean) {
  const [isOpen, setIsOpen] = useState<boolean>(initialValue || false);

  function toggleModal() {
    setIsOpen((prevValue) => !prevValue);
  }

  return {
    isOpen,
    toggleModal,
    setIsOpen,
  };
}

export default useModal;
