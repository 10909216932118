import React, { createContext, useReducer } from "react";

import ToastsContainer, { TToastProps, TToastType } from "@web/components/Toast/Toast";

type TC = {
  success: (message: string, timer?: number, showClose?: boolean) => void;
  warning: (message: string, timer?: number, showClose?: boolean) => void;
  error: (message: string, timer?: number, showClose?: boolean) => void;
  remove: (id: number) => void;
  alert: (message: string, timer?: number, showClose?: boolean) => void;
  primary: (message: string, timer?: number, showClose?: boolean) => void;
};

const initialState = {
  toasts: [],
};
export const ToastContext = createContext<TC>({
  success: () => null,
  warning: () => null,
  error: () => null,
  remove: () => null,
  alert: () => null,
  primary: () => null,
});

type TActionProp = {
  type: "ADD_TOAST" | "DELETE_TOAST";
  payload: unknown;
};

export const toastReducer = (state: { toasts: TToastProps[] }, action: TActionProp) => {
  switch (action.type) {
    case "ADD_TOAST":
      return {
        ...state,
        toasts: [...state.toasts, action.payload],
      };
    case "DELETE_TOAST": {
      const updatedToasts = state.toasts.filter((toast: TToastProps) => toast.id !== action.payload);
      return {
        ...state,
        toasts: updatedToasts,
      };
    }
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const ToastContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(toastReducer, initialState);

  const addToast = (type: TToastType, message: string, timer?: number, showClose?: boolean) => {
    const id = Math.floor(Math.random() * 10000000);
    dispatch({ type: "ADD_TOAST", payload: { id, message, type, timer, showClose } });
  };

  const remove = (id: number) => {
    dispatch({ type: "DELETE_TOAST", payload: id });
  };

  const success = (message: string, timer?: number, showClose?: boolean) => {
    addToast("success", message, timer, showClose);
  };

  const warning = (message: string, timer?: number, showClose?: boolean) => {
    addToast("warning", message, timer, showClose);
  };

  const error = (message: string, timer?: number, showClose?: boolean) => {
    addToast("error", message, timer, showClose);
  };

  const alert = (message: string, timer?: number, showClose?: boolean) => {
    addToast("alert", message, timer, showClose);
  };

  const primary = (message: string, timer?: number, showClose?: boolean) => {
    addToast("primary", message, timer, showClose);
  };

  const value = { success, warning, error, remove, alert, primary };

  return (
    <ToastContext.Provider value={value}>
      <ToastsContainer toasts={state.toasts} />
      {children}
    </ToastContext.Provider>
  );
};
