import { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";

import Icon from "../Icon/Icon";
import Loader from "../Loader/Loader";

import * as styles from "./Evidences.css";

import { useChecklistContext } from "@web/context/ChecklistContext";
import { ui } from "@web/styles/palette.css";
import { TEvidence } from "@web/types/ai";
import cx from "@web/utils/cx";

const Evidences = ({
  evidences = [],
  isAiAnswersLoading = false,
}: {
  evidences?: TEvidence[];
  isAiAnswersLoading?: boolean;
}) => {
  const [showEvidences, setShowEvidences] = useState<boolean>(false);
  const { setEvidenceToScrollTo } = useChecklistContext();

  const handleChange = () => {
    setShowEvidences((preValue) => !preValue);
  };

  const handleLocateEvidence = (evidence: TEvidence) => {
    setEvidenceToScrollTo(evidence);
  };

  if (isAiAnswersLoading) {
    return (
      <>
        <div className={styles.loadingWrap}>
          <div className={styles.leftWraper}>Loading evidences...</div>
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {evidences.length === 0 ? (
        <div className={styles.leftWraper}>No evidence found</div>
      ) : (
        <Accordion allowZeroExpanded={true} onChange={handleChange}>
          <AccordionItem className={cx(styles, { expanded: showEvidences })}>
            <AccordionItemHeading>
              <AccordionItemButton className={cx(styles, { statItem: true, title: true })}>
                <div className={styles.leftWraper}>
                  {showEvidences ? "Hide" : "Show"}
                  {` Evidence${evidences.length > 1 ? "s" : ""} `}
                  {isAiAnswersLoading ? (
                    <div className={styles.loaderContainer}>
                      <Loader />
                    </div>
                  ) : (
                    `(${evidences.length})`
                  )}
                  <Icon className={styles.icon} size={16} name="down" color={ui.secondary} />
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              {evidences.map((evidence) => (
                <div key={evidence.id}>
                  <div className={styles.divider} />
                  <div className={styles.headingContainer}>
                    <div className={styles.heading}>Evidence on page {evidence.spans[0]?.pages.start}</div>
                    <div className={styles.headingIconsContainer}>
                      <Icon
                        name="copy"
                        size={16}
                        color={ui.primary}
                        className={styles.icon}
                        onClick={() => {
                          navigator.clipboard.writeText(evidence.text);
                        }}
                      />
                      <Icon
                        name="locate"
                        size={16}
                        color={ui.primary}
                        className={styles.icon}
                        onClick={() => handleLocateEvidence(evidence)}
                      />
                    </div>
                  </div>
                  <div className={styles.description}>{evidence.text}</div>
                </div>
              ))}
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};

export default Evidences;
