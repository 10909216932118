import React from "react";

import DatePickerField from "../DatePickerField";
import Evidences from "../Evidences";
import Icon from "../Icon/Icon";
import MultiSelect from "../MultiSelect";
import RichTextArea from "../RichTextArea";
import SelectDropDown from "../SelectDropDown";
import TextArea from "../TextArea/TextArea";

import * as styles from "./TemplateFields.css";

import { useProjectContext } from "@web/context/ProjectContext";
import { useSessionContext } from "@web/context/SessionContext";
import { ui } from "@web/styles/palette.css";
import { TEvidence } from "@web/types/ai";
import { TFeatureFlags } from "@web/types/feature-flag";
import { TTemplateFieldSchema } from "@web/types/template";
import { getRelatedDocuments } from "@web/utils/document";

type TTemplateFieldsProps = {
  disabled?: boolean;
  fields: TTemplateFieldSchema[];
  layoutType?: "horizontal" | "vertical";
  aiEnabled?: boolean;
  isAiAnswersLoading?: boolean;
  evidences?: TEvidence[];
};

function TemplateFields({
  disabled = false,
  fields,
  layoutType = "horizontal",
  aiEnabled = false,
  isAiAnswersLoading = false,
  evidences = [],
}: TTemplateFieldsProps) {
  const { project } = useProjectContext();
  const { featureFlagsWithStatus, isFeatureFlagEnabled } = useSessionContext();

  const getDocuments = getRelatedDocuments(`${project?.id}`);

  const aiFieldsFeatureFlag = featureFlagsWithStatus?.find(
    (featureFlagInfo) => featureFlagInfo.featureFlag.tag === TFeatureFlags.AI_FIELDS && featureFlagInfo.isEnabled,
  );

  const getEvidencesForTheAiField = (aiFieldId: string): TEvidence[] => {
    return evidences.filter((evidence) => evidence.aiFieldId === aiFieldId);
  };

  const isRichTextAllowed = isFeatureFlagEnabled(TFeatureFlags.RICH_TEXT_SUPPORT);

  return fields.length > 0 ? (
    <section className={layoutType === "horizontal" ? styles.root?.horizontal : styles.root?.vertical}>
      {fields.map((field: TTemplateFieldSchema, index: number) => {
        const { type, key, placeholder, options, handler, aiFieldId } = field;
        let element: React.ReactElement = <></>;
        if (type === "free-text") {
          element = isRichTextAllowed ? (
            <RichTextArea disabled={disabled} name={key} placeholder={placeholder} label="" resizable />
          ) : (
            <TextArea disabled={disabled} name={key} placeholder={placeholder} label="" resizable />
          );
        }
        if (type === "date") {
          element = (
            <div>
              <DatePickerField disabled={disabled} name={key} placeholder="DD/MM/YYYY" label="" variant="grey" />
            </div>
          );
        }
        if (type === "multi-select") {
          element = <MultiSelect disabled={disabled} options={options} name={key} variant="grey" />;
        }

        if (type === "multi-select" && handler === "documents") {
          element = (
            <MultiSelect
              disabled={disabled}
              async={true}
              withPaginate={true}
              loadPaginateOptions={getDocuments}
              name={key}
              variant="grey"
            />
          );
        }

        if (type === "select" || type === "yes-no") {
          element = <SelectDropDown disabled={disabled} name={key} label="" options={options} variant="grey" />;
        }
        return (
          <div
            className={layoutType === "horizontal" ? styles.fieldWrapper?.horizontal : styles.fieldWrapper?.vertical}
            key={key}
          >
            <div className={styles.name}>
              <label className={styles.label}>
                {`${index + 1}. ${field.label}`} {field.isRequired ? <span className={styles.required}>*</span> : null}
                {aiFieldId && aiFieldsFeatureFlag?.isEnabled && <Icon name="ai" size={16} color={ui.primary} />}
              </label>

              <p className={styles.description}>{field.description}</p>
            </div>
            <div>{element}</div>
            {layoutType === "vertical" && aiEnabled && field.aiFieldId && (
              <Evidences
                isAiAnswersLoading={isAiAnswersLoading}
                evidences={getEvidencesForTheAiField(field.aiFieldId)}
              />
            )}
          </div>
        );
      })}
    </section>
  ) : (
    <></>
  );
}

export default TemplateFields;
