import Link, { LinkProps } from "next/link";
import React, { MouseEventHandler } from "react";

import Icon, { IconName } from "../Icon/Icon";

import * as styles from "./Button.css";

import cx from "@web/utils/cx";

export type ButtonVariant =
  | "primary"
  | "secondary"
  | "caution"
  | "black"
  | "white"
  | "link"
  | "primary-link"
  | "success"
  | "delete"
  | "primary-fade"
  | "delete-fill"
  | "black-link";

type ButtonProps = {
  label: string;
  href?: LinkProps["href"];
  onClick?: MouseEventHandler<HTMLButtonElement> | (() => void);
  variant?: ButtonVariant;
  disabled?: boolean;
  icon?: IconName | null;
  iconPosition?: "left" | "right";
  type?: "button" | "submit";
  className?: string;
  iconSize?: number;
  betaTag?: boolean;
};

function Button({
  href,
  onClick,
  variant = "primary",
  disabled,
  label,
  icon,
  iconPosition = "right",
  type = "button",
  className,
  iconSize = 16,
  betaTag = false,
}: ButtonProps): React.ReactElement {
  const rootClassName = cx(styles, {
    root: true,
    secondary: variant === "secondary",
    caution: variant === "caution",
    black: variant === "black",
    link: variant === "link",
    white: variant === "white",
    primaryLink: variant === "primary-link",
    primaryFade: variant === "primary-fade",
    success: variant === "success",
    deleteBtn: variant === "delete",
    deleteFillBtn: variant === "delete-fill",
    blackLink: variant === "black-link",
  });

  const buttonIcon = icon != null ? <Icon name={icon} size={iconSize} className={styles.icon} /> : null;

  const button = (
    <button className={[rootClassName, className].join(" ")} type={type} onClick={onClick} disabled={disabled}>
      {iconPosition === "left" && buttonIcon}
      {label}
      {iconPosition === "right" && buttonIcon}
    </button>
  );
  return href != null ? (
    <Link passHref href={href}>
      {button}
    </Link>
  ) : (
    <>
      {betaTag && <div className={styles.tag}>Beta</div>}
      {button}
    </>
  );
}

export default Button;
