import { ChangeEventHandler } from "react";

import Avatar from "../Avatar/Avatar";

import * as styles from "./UsersList.css";

type UserCheckboxProps = {
  name?: string;
  image?: string;
  email?: string;
  jobTitle?: string | null;
  department?: string | null;
  selected: boolean;
  onClick?: ChangeEventHandler<HTMLInputElement> | (() => void);
  checkbox?: boolean;
};

const UserCheckbox = ({
  name,
  image,
  email,
  jobTitle,
  department,
  selected,
  onClick,
  checkbox = true,
}: UserCheckboxProps) => {
  return (
    <div className={styles.root}>
      <div className={styles.userIconContainer}>
        <Avatar name={name} image={image} />
        <div className={styles.nameContainer}>
          <div className={styles.userName}>{name}</div>
          {jobTitle && <div className={styles.jobTitle}>{jobTitle}</div>}
        </div>
      </div>
      <span title={department || "-"} className={styles.department}>
        {department || "-"}
      </span>
      <span className={styles.email}>{email}</span>
      {checkbox && <input type="checkbox" id={email} checked={selected} onChange={onClick} />}
    </div>
  );
};

export default UserCheckbox;
