import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useDocumentTabsContext } from "./DocumentTabsContext";
import { useIndexTableContext } from "./IndexTableContext";
import { useProjectContext } from "./ProjectContext";

import useModal from "@web/hooks/useModal";
import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import {
  DocumentHostType,
  TChangeTrackerResponse,
  TChangeTrackerStatus,
  TDocType,
  TDocument,
  TDocumentList,
} from "@web/types/document";
import { sortDocuments } from "@web/utils/document";

type TC = {
  changeTrackerStatus?: TChangeTrackerStatus;
  handleStatusChange: (status: TChangeTrackerStatus) => void;
  getChangeTrackerDetails: () => void;
  changeTrackerDetails?: TChangeTrackerResponse;
  isChangeTrackerOpened: boolean;
  refreshChangeTrackerDetails: () => void;
  isUpDisabled: boolean;
  isDownDisabled: boolean;
  handleDownClick: () => void;
  handleUpClick: () => void;
  toggleOpenChangeTracker: () => void;
  hierarchy: TDocumentList;
  currentDocument?: TDocument;
};

export const ChangeTrackerContext = createContext<TC>({
  changeTrackerStatus: undefined,
  handleStatusChange: () => undefined,
  getChangeTrackerDetails: () => undefined,
  changeTrackerDetails: undefined,
  isChangeTrackerOpened: false,
  refreshChangeTrackerDetails: () => undefined,
  isUpDisabled: false,
  isDownDisabled: false,
  handleDownClick: () => undefined,
  handleUpClick: () => undefined,
  toggleOpenChangeTracker: () => undefined,
  hierarchy: [],
});

export const useChangeTrackerContext = (): TC => {
  return useContext(ChangeTrackerContext);
};

export const ChangeTrackerContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { isCoordinator } = useProjectContext();
  const { getSubRows } = useIndexTableContext();
  const { selectedSubTab } = useDocumentTabsContext();

  const router = useRouter();
  const { error: errorToast } = useToast();
  const {
    isOpen: isChangeTrackerOpened,
    toggleModal: toggleOpenChangeTracker,
    setIsOpen: setIsChangeTrackerOpened,
  } = useModal();
  const [changeTrackerStatus, setChangeTrackerStatus] = useState<TChangeTrackerStatus>();
  const [changeTrackerDetails, setChangeTrackerDetails] = useState<TChangeTrackerResponse>();
  const [documents, setDocuments] = useState<TDocumentList>();
  const [currentDocument, setCurrentDocument] = useState<TDocument | undefined>();
  const [isUpDisabled, setIsUpDisabled] = useState<boolean>(false);
  const [isDownDisabled, setIsDownDisabled] = useState<boolean>(false);
  const [hierarchy, setHierarchy] = useState<TDocumentList>([]);
  const id = router.query.id as string;

  const handleStatusChange = (status: TChangeTrackerStatus) => {
    setCurrentDocument(undefined);
    setIsUpDisabled(true);
    const statusDetails = changeTrackerDetails?.stats.find((stat) => stat.status === status && stat.count);
    statusDetails && statusDetails?.count > 0 ? setIsDownDisabled(false) : setIsDownDisabled(true);
    setChangeTrackerStatus(status);
    changeTrackerStatus !== TChangeTrackerStatus.DELETED && getDocuments(status);
  };

  const getChangeTrackerDetails = async () => {
    try {
      if (isCoordinator) {
        const response = await http.getChangeTrackerDetails(id);
        setChangeTrackerDetails(response);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        errorToast(err.message);
      }
    }
  };

  const refreshChangeTrackerDetails = () => {
    if (isChangeTrackerOpened === true) {
      getChangeTrackerDetails();
      changeTrackerStatus !== TChangeTrackerStatus.DELETED && changeTrackerStatus && getDocuments(changeTrackerStatus);
    }
  };

  const getDocuments = async (status: TChangeTrackerStatus) => {
    try {
      const response = await (
        await http.getIndexDocument(id, { syncStatus: status, type: DocumentHostType.NON_HOSTED })
      ).slice();
      const sortedDocuments = sortDocuments(response);
      const documents = sortedDocuments.filter((document) => document.type === TDocType.Document);
      setDocuments(documents);
    } catch (err: unknown) {
      if (err instanceof Error) {
        errorToast(err.message);
      }
    }
  };

  const checkUpValidity = () => {
    if (!currentDocument) return setIsUpDisabled(true);
    if (documents && currentDocument) {
      const index = documents.indexOf(currentDocument);
      if (index - 1 <= -1) return setIsUpDisabled(true);
      return setIsUpDisabled(false);
    }
  };

  const checkDownValidity = () => {
    if (documents && currentDocument) {
      const index = documents.indexOf(currentDocument);
      if (index + 1 >= documents.length) return setIsDownDisabled(true);
      return setIsDownDisabled(false);
    }
  };

  const handleDownClick = () => {
    if (documents) {
      !currentDocument && setCurrentDocument(documents[0]);
      if (currentDocument) {
        const index = documents.indexOf(currentDocument);
        index + 1 <= documents.length && setCurrentDocument(documents[index + 1]);
      }
    }
  };

  const handleUpClick = () => {
    if (documents) {
      !currentDocument && setCurrentDocument(documents[0]);
      if (currentDocument) {
        const index = documents.indexOf(currentDocument);
        index - 1 >= 0 && setCurrentDocument(documents[index - 1]);
      }
    }
  };

  const getDocumentHierarchy = async (id: string) => {
    try {
      const response = await http.getDocumentHierarchy(id);
      const filteredDocuments = response.filter(
        (doc) => !doc.syncStatuses || !doc.syncStatuses.includes(TChangeTrackerStatus.DELETED),
      );

      setHierarchy([...hierarchy, ...filteredDocuments]);
    } catch (err: unknown) {
      if (err instanceof Error) {
        errorToast(err.message);
      }
    }
    return [];
  };

  useEffect(() => {
    if (hierarchy) {
      hierarchy.forEach((document) => {
        document.type === TDocType.Folder && getSubRows(document.id);
      });
    }
  }, [hierarchy]);

  useEffect(() => {
    checkUpValidity();
    checkDownValidity();
    currentDocument && getDocumentHierarchy(currentDocument.id);
  }, [currentDocument]);

  useEffect(() => {
    !isChangeTrackerOpened && setChangeTrackerStatus(undefined);
    isChangeTrackerOpened && handleStatusChange(TChangeTrackerStatus.NEW);
  }, [isChangeTrackerOpened]);

  useEffect(() => {
    setIsChangeTrackerOpened(false);
  }, [id, selectedSubTab]);

  return (
    <ChangeTrackerContext.Provider
      value={{
        changeTrackerStatus,
        handleStatusChange,
        getChangeTrackerDetails,
        changeTrackerDetails,
        isChangeTrackerOpened,
        refreshChangeTrackerDetails,
        isUpDisabled,
        isDownDisabled,
        handleDownClick,
        handleUpClick,
        toggleOpenChangeTracker,
        hierarchy,
        currentDocument,
      }}
    >
      {children}
    </ChangeTrackerContext.Provider>
  );
};
