import { useState } from "react";

import NotificationList from "../NotificationList";
import OutsideClickHandler from "../OutsideClickHandler/OutsideClickHandler";

import * as styles from "./Notification.css";

import Icon from "@web/components/Icon/Icon";
import { useNotificationContext } from "@web/context/NotificationContext";

export default function Notification() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { unreadCount, clearNotifications } = useNotificationContext();

  const toggleNotification = () => {
    if (isOpen) {
      clearNotifications();
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  return (
    <div className={styles.root}>
      <OutsideClickHandler onOutsideClick={() => isOpen && toggleNotification()}>
        <div className={styles.bellIcon} onClick={toggleNotification}>
          <Icon name="bell" />
          {unreadCount > 0 && <div className={styles.unreadCount}>{unreadCount}</div>}
        </div>
        {isOpen && <NotificationList toggleList={toggleNotification} />}
      </OutsideClickHandler>
    </div>
  );
}
