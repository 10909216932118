import React, { createContext, SetStateAction, useContext, useEffect, useState, Dispatch } from "react";

import { useOtherDocumentsContext } from "./OtherDocumentsContext";
import { useProjectContext } from "./ProjectContext";

type TC = {
  isIndexExpandAllRows?: boolean;
  setIndexExpandAllRows: Dispatch<SetStateAction<boolean>>;
  isOtherExpandAllRows?: boolean;
  setOtherExpandAllRows: Dispatch<SetStateAction<boolean>>;
};

export const ExpandIndexTableContext = createContext<TC>({
  isIndexExpandAllRows: false,
  setIndexExpandAllRows: () => null,
  isOtherExpandAllRows: false,
  setOtherExpandAllRows: () => null,
});

export const useExpandIndexTableContext = (): TC => {
  return useContext(ExpandIndexTableContext);
};

export const ExpandIndexTableContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { expandAllRows: expandOtherDocumentsTable } = useOtherDocumentsContext();
  const { project } = useProjectContext();

  const [isIndexExpandAllRows, setIndexExpandAllRows] = useState<boolean>(false);
  const [isOtherExpandAllRows, setOtherExpandAllRows] = useState<boolean>(false);

  useEffect(() => {
    setIndexExpandAllRows(false);
    setOtherExpandAllRows(false);
  }, [project?.id]);

  useEffect(() => {
    isOtherExpandAllRows && expandOtherDocumentsTable();
  }, [isOtherExpandAllRows]);

  return (
    <ExpandIndexTableContext.Provider
      value={{ isIndexExpandAllRows, setIndexExpandAllRows, isOtherExpandAllRows, setOtherExpandAllRows }}
    >
      {children}
    </ExpandIndexTableContext.Provider>
  );
};
