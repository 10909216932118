import Image from "next/image";
import React from "react";

import Icon from "../Icon/Icon";

import * as styles from "./Avatar.css";

import { userAvatarColor } from "@web/hooks/userAvatarColor";

type TAvatarProps = {
  name?: string;
  image?: string | null;
  size?: number;
  font?: number;
  backgroundColor?: string;
  avatarText?: string;
};

function Avatar({ name, image, size = 32, font = 14, backgroundColor, avatarText }: TAvatarProps): React.ReactElement {
  const color = name && userAvatarColor(name);
  const background = backgroundColor ? backgroundColor : color;
  const text = (avatarText ?? name?.charAt(0).toUpperCase()) || "";
  const renderIconContent = () => {
    if (image)
      return <Image className={styles.avatarImage} src={image} alt={name || "avatar"} width={size} height={size} />;
    if (text)
      return (
        <div className={styles.avatarImageWrapper} style={{ backgroundColor: background, fontSize: `${font}px` }}>
          {text}
        </div>
      );
    return <Icon name="user" size={size} />;
  };

  return (
    <div className={styles.root} style={{ width: `${size}px`, height: `${size}px` }}>
      {renderIconContent()}
    </div>
  );
}

export function AvatarCountPlaceholder({ name, size = 32 }: TAvatarProps): React.ReactElement {
  const color = userAvatarColor(name);
  return (
    <div className={styles.root} style={{ width: `${size}px`, height: `${size}px` }}>
      <div className={styles.avatarImageWrapper} style={{ backgroundColor: color }}>
        {name}
      </div>
    </div>
  );
}

export default Avatar;
