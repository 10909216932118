import moment from "moment";

/**
 * Returns a human-readable string representing the time difference between a given date string and the current time.
 * @param dateString - The date string to format (format: 'YYYY-MM-DD HH:mm:ss.SSSSSSZ').
 * @returns A string representing the time difference (e.g., 'just now', '1 min ago', '12:20 PM 01-09-24').
 */
export function getTimeAgoString(dateString?: string): string {
  const date = moment(dateString, "YYYY-MM-DD HH:mm:ss.SSSSSSZ");
  const currentDate = moment();
  const diffMinutes = currentDate.diff(date, "minutes");

  if (diffMinutes === 0) {
    return "just now";
  } else if (diffMinutes === 1) {
    return "1 min ago";
  } else if (diffMinutes < 60) {
    return `${diffMinutes} mins ago`;
  } else if (diffMinutes < 24 * 60) {
    return `${Math.floor(diffMinutes / 60)} hours ago`;
  } else if (date.isSame(currentDate, "day")) {
    return `${date.format("hh:mm A")}, Today`;
  } else if (date.isSame(currentDate.clone().subtract(1, "day"), "day")) {
    return `${date.format("hh:mm A")}, Yesterday`;
  } else {
    return date.format("hh:mm A, D MMMM, YYYY");
  }
}
