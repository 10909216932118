import debounce from "debounce-promise";

import { http } from "@web/services/withAuth";
import { TDocument } from "@web/types/document";

export const sortDocuments = (documents: TDocument[], sortByName?: boolean) => {
  if (sortByName) {
    return sortDocumentsByName(documents);
  }
  return documents.sort(function (a, b) {
    const num1 = a.number.split(".");
    const num2 = b.number.split(".");

    for (let i = 0; i < num1.length; i++) {
      if (num2[i]) {
        if (num1[i] !== num2[i]) {
          return +num1[i] - +num2[i];
        }
      } else {
        return 1;
      }
    }
    return -1;
  });
};

const sortDocumentsByName = (documents: TDocument[]) => {
  return documents.sort((a, b) => a.name.localeCompare(b.name));
};

export const getRelatedDocuments = (projectId: string) =>
  debounce(async (search: string, data: unknown, d: { page: number }) => {
    try {
      const paginate = { page: d.page, limit: 20 };
      const params = search ? { search, ...paginate } : paginate;
      const response = await http.getRelatedDocuments(`${projectId}`, params);
      const data = response.items.map((document) => ({
        ...document,
        value: document.id,
        label: `${document.number}` + " " + `${document.name}`,
      }));
      return {
        options: data,
        hasMore: response.meta.totalPages > response.meta.currentPage,
        additional: {
          page: d.page + 1,
        },
      };
    } catch (error: unknown) {
      console.log(error);
    }
  }, 400);
