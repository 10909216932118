import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

type TC = {
  setRaisedIssueDocumentId: Dispatch<SetStateAction<string>>;
  raisedIssueDocumentId: string;
};

export const IssueCountContext = createContext<TC>({
  setRaisedIssueDocumentId: () => null,
  raisedIssueDocumentId: "",
});

export const useIssueCountContext = (): TC => {
  return useContext(IssueCountContext);
};

export const IssueCountContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [raisedIssueDocumentId, setRaisedIssueDocumentId] = useState<string>("");

  return (
    <IssueCountContext.Provider value={{ raisedIssueDocumentId, setRaisedIssueDocumentId }}>
      {children}
    </IssueCountContext.Provider>
  );
};
