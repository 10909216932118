import { UseFormRegisterReturn } from "react-hook-form";

import * as styles from "./TextBox.css";

import cx from "@web/utils/cx";

type TextBoxProps = {
  className?: string;
  disabled?: boolean;
  label: string;
  required?: boolean;
  error?: string;
  placeholder?: string;
  formRegister?: UseFormRegisterReturn<string>;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
};

export const TextBox = ({
  className,
  disabled,
  label,
  required,
  error,
  placeholder,
  formRegister,
  value,
  onChange,
}: TextBoxProps) => {
  return (
    <label className={[styles.root, className, disabled].join(" ")}>
      <div className={styles.label}>
        {label}
        {required && <span className={styles.required}>*</span>}
      </div>
      <input
        className={cx(styles, {
          input: true,
          error: error != null,
        })}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        {...formRegister}
      />
      {error != null ? <div className={styles.errors}>{error}</div> : null}
    </label>
  );
};
