import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react";

import { TDocument } from "@web/types/document";

type TC = {
  selectedRows: TDocument[];
  setSelectedRows: Dispatch<SetStateAction<unknown[]>>;
};

export const IndexTableSelectContext = createContext<TC>({
  selectedRows: [],
  setSelectedRows: () => undefined,
});

export const useIndexTableSelectContext = (): TC => {
  return useContext(IndexTableSelectContext);
};

export const IndexTableSelectContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedRows, setSelectedRows] = useState<TDocument[]>([]);

  return (
    <IndexTableSelectContext.Provider value={{ selectedRows, setSelectedRows }}>
      {children}
    </IndexTableSelectContext.Provider>
  );
};
