import * as DOMPurify from "dompurify";
import { DeltaInsertOp, QuillDeltaToHtmlConverter } from "quill-delta-to-html";

import { TDeltaStatic } from "@web/types/rich-editor";
import { TUserBase } from "@web/types/user";

export const getHtmlFromDeltaJson = (deltaJson: TDeltaStatic, userNamesMapById?: Record<TUserBase["id"], string>) => {
  const deltaOperations = deltaJson["ops"];
  const converter = new QuillDeltaToHtmlConverter(deltaOperations, {});
  converter.renderCustomWith((customOp: DeltaInsertOp) => {
    if (customOp.insert.type === "mention") {
      const mention = customOp.insert.value;
      const mentionValue = userNamesMapById ? userNamesMapById[mention.id] : mention.value;
      return `
            <span
              class="quill-mention-link"
              data-index="${mention.index}"
              data-denotation-char="${mention.denotationChar}"
              data-key="${mention.id}"
              data-link="${mention.link}"
              data-value='${mentionValue}'
            >
              <span contenteditable="false">
                @${mentionValue}
              </span>
            </span>
            `;
    }
    return "";
  });

  const html = converter.convert();
  return DOMPurify.sanitize(html);
};
