import { useRouter } from "next/router";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useState } from "react";

import { useProjectContext } from "./ProjectContext";

import { TDocumentSubTab, TDocumentTab } from "@web/types/document";
type TC = {
  selectedTab: TDocumentTab;
  selectedSubTab: TDocumentSubTab;
  setSelectedTab: Dispatch<SetStateAction<TDocumentTab>>;
  setSelectedSubTab: Dispatch<SetStateAction<TDocumentSubTab>>;
};

export const DocumentTabsContext = createContext<TC>({
  selectedTab: TDocumentTab.ALL_DOCUMENTS,
  selectedSubTab: "Data Room Documents",
  setSelectedTab: () => null,
  setSelectedSubTab: () => null,
});

export const useDocumentTabsContext = (): TC => {
  return useContext(DocumentTabsContext);
};

export const DocumentTabsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { project } = useProjectContext();
  const tab = (router.query?.tab as TDocumentTab) || TDocumentTab.ALL_DOCUMENTS;
  const projectId = project?.id;
  const [selectedTab, setSelectedTab] = useState<TDocumentTab>(tab);
  const [selectedSubTab, setSelectedSubTab] = useState<TDocumentSubTab>("Data Room Documents");

  useEffect(() => {
    setSelectedTab(tab);
  }, [tab]);

  useEffect(() => {
    if (projectId && selectedTab === TDocumentTab.MY_DOCUMENTS) {
      selectedSubTab !== "Data Room Documents" && setSelectedSubTab("Data Room Documents");
    }
    if (projectId && selectedTab === TDocumentTab.CHECKLISTS_FOR_SENIOR_REVIEW) {
      selectedSubTab !== "Data Room Documents" && setSelectedSubTab("Data Room Documents");
    }
  }, [project, selectedTab]);

  return (
    <DocumentTabsContext.Provider value={{ selectedTab, setSelectedTab, selectedSubTab, setSelectedSubTab }}>
      {children}
    </DocumentTabsContext.Provider>
  );
};
