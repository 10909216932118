import Image from "next/image";
import React from "react";

import Modal from "../Modal";

import * as styles from "./Loader.css";

const Loader = ({ variant, size = 90 }: { variant?: "modal"; size?: number }): React.ReactElement => {
  if (variant === "modal") {
    return (
      <Modal isOpen={true}>
        <div className={styles.modalContainer}>
          <Image width="120" height="120" src="/icons/loader.gif" alt="Loader" />
        </div>
      </Modal>
    );
  }
  return (
    <div className={styles.root}>
      <Image width={size} height={size} src="/icons/loader.gif" alt="Loader" />
    </div>
  );
};

export default Loader;
