import { useState } from "react";

import { TMeta } from "@web/types/api";

function usePagination() {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const limit = 20;

  const resetPagination = () => {
    setHasMore(false);
    setPageNumber(1);
  };

  const setPaginationData = (data: TMeta) => {
    setPageNumber(data.currentPage);
    if (data.currentPage === data.totalPages || data.totalPages === 0) {
      setHasMore(false);
    } else {
      setHasMore(true);
      setPageNumber(pageNumber + 1);
    }
  };

  return {
    limit,
    pageNumber,
    hasMore,
    setPaginationData,
    resetPagination,
  };
}

export default usePagination;
