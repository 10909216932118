import * as styles from "./PageNotFound.css";

import App from "@web/components/App/App";

function PageNotFound() {
  return (
    <App>
      <div className={styles.root}>
        <h1>Oops!</h1>
        <div className={styles.subHeading}>404 - Page not found</div>
        <p className={styles.description}>
          The page you are looking for might have been removed, had its name changed or unavailable
        </p>
      </div>
    </App>
  );
}

export default PageNotFound;
