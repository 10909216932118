import { useRouter } from "next/router";
import { signIn, useSession } from "next-auth/react";
import React from "react";

import PageNotFound from "../PageNotFound";
import UnAuthorized from "../UnAuthorized";

import routes from "@web/constants/routes";
import { EUserRoles } from "@web/types/user";

function Authorization({ children }: { children: React.ReactNode }): React.ReactElement {
  const { data, status } = useSession();
  const router = useRouter();

  const user = data?.user;
  const error = data?.error;

  if (error?.message.includes("Inactive organization")) {
    error.message = "Organisation is disabled, please contact organisation administrator";
  }

  if (error?.message) {
    const errorMessage =
      error.message === "RefreshAccessTokenError"
        ? "You've been logged out due to inactivity. Redirecting you to login page."
        : error.message;
    return <UnAuthorized message={errorMessage} />;
  }

  let allowed = false;
  const path: string = router.pathname;
  const pathname = path.toLocaleLowerCase();

  if (!pathname.includes("projects") && !pathname.includes("organisations") && pathname !== "/") {
    return <PageNotFound />;
  }

  if (status === "unauthenticated") {
    signIn("cognito");
    return <></>;
  }

  if (status === "loading") {
    return <></>;
  }

  if (router.pathname === "/" && user?.role == EUserRoles.PLATFORM_ADMIN) {
    router.push("/organisations");
    return <></>;
  } else if (
    router.pathname === "/" &&
    (user?.role == EUserRoles.ORGANIZATION_ADMIN || user?.role == EUserRoles.ORGANIZATION_USER)
  ) {
    router.push("/projects");
    return <></>;
  }

  if (user?.role && routes[user.role].includes(path)) {
    allowed = true;
  }

  return allowed ? (
    <>{children}</>
  ) : (
    <UnAuthorized message="You are not authorized to view this page, please sign in with different account" />
  );
}

export default Authorization;
