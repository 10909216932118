import { useEffect, useRef } from "react";

import * as styles from "./Toast.css";

import { useToast } from "@web/hooks/useToast";
import cx from "@web/utils/cx";

export type TToastType = "success" | "warning" | "error" | "alert" | "primary";

export type TToastProps = {
  message: string;
  id: number;
  type: TToastType;
  timer?: number;
  showClose?: boolean;
};

const Toast = ({ message, id, type, timer = 5000, showClose = true }: TToastProps) => {
  const timerID = useRef<ReturnType<typeof setInterval>>();
  const toast = useToast();

  const handleDismiss = () => {
    toast.remove(id);
  };

  useEffect(() => {
    timerID.current = setTimeout(() => {
      handleDismiss();
    }, timer);

    return () => {
      clearTimeout(timerID.current);
    };
  }, []);

  const rootStyles = {
    container: true,
    [type]: true,
  };

  return (
    <div className={cx(styles, rootStyles)}>
      <p>{message}</p>
      {showClose && (
        <p className={styles.close} onClick={handleDismiss}>
          close
        </p>
      )}
    </div>
  );
};

const ToastsContainer = ({ toasts }: { toasts: TToastProps[] }) => {
  return (
    <div className={styles.root}>
      {toasts.map((toast: TToastProps) => (
        <Toast key={toast.id} {...toast} />
      ))}
    </div>
  );
};

export default ToastsContainer;
