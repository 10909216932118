import { ExpandedState, Table } from "@tanstack/react-table";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";

import { useDocumentTabsContext } from "./DocumentTabsContext";
import { useProjectContext } from "./ProjectContext";

import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import {
  DocumentHostType,
  TChangeTrackerStatus,
  TDocStatus,
  TDocType,
  TDocument,
  TDocumentList,
  TDocumentRows,
  TDocumentTab,
} from "@web/types/document";
import { TProjectIndexType } from "@web/types/project";
import { sortDocuments } from "@web/utils/document";

type TC = {
  setTableData: Dispatch<SetStateAction<readonly TDocument[]>>;
  tableData: TDocumentList;
  subRows: TDocumentRows[];
  updateRowData: (documentInfo: { document?: TDocument | undefined; documentId?: string | undefined }) => Promise<void>;
  setSubRows: Dispatch<SetStateAction<TDocumentRows[]>>;
  getSubRows: (id: string) => void;
  expandAllRows: () => void;
  expandedRows: ExpandedState;
  setExpandedRows: Dispatch<SetStateAction<ExpandedState>>;
  collapseExpandedRows: () => void;
  isDataLoading: boolean;
  setIsTableDataLoading: Dispatch<SetStateAction<boolean>>;
  getIndexDocument: (indexType?: TProjectIndexType, withLoadingStatus?: boolean) => void;
  setDocumentsInTable: (documents: TDocument[], indexType?: TProjectIndexType) => void;
  updateDocument: (documentId: string, updatedData: TDocument) => void;
  lastInteractedDocumentID: string | null;
  setLastInteractedDocumentID: Dispatch<SetStateAction<string | null>>;
  setTableRef: (table: unknown) => void;
};

export const IndexTableContext = createContext<TC>({
  setTableData: () => [],
  tableData: [],
  subRows: [],
  updateRowData: () => Promise.resolve(),
  setSubRows: () => [],
  getSubRows: () => [],
  expandAllRows: () => undefined,
  expandedRows: {},
  setExpandedRows: () => {},
  collapseExpandedRows: () => {},
  isDataLoading: false,
  setIsTableDataLoading: () => {},
  getIndexDocument: () => {},
  setDocumentsInTable: () => {},
  updateDocument: () => {},
  lastInteractedDocumentID: null,
  setLastInteractedDocumentID: () => {},
  setTableRef: () => {},
});

export const useIndexTableContext = (): TC => {
  return useContext(IndexTableContext);
};

export const IndexTableContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { error: errorToast } = useToast();
  const [subRows, setSubRows] = useState<TDocumentRows[]>([]);
  const [tableData, setTableData] = useState<TDocumentList>([]);
  const [isDataLoading, setIsTableDataLoading] = useState<boolean>(true);
  const [expandedRows, setExpandedRows] = useState<ExpandedState>({});
  const tableDataRef = useRef<TDocumentList>(tableData);
  tableDataRef.current = tableData;
  const { selectedTab } = useDocumentTabsContext();
  const lastActiveTabRef = useRef<string>(selectedTab);
  const [lastInteractedDocumentID, setLastInteractedDocumentID] = useState<string | null>(null);
  const { project } = useProjectContext();
  const projectId = project?.id;

  const tableRef = useRef<unknown | null>(null);

  const setTableRef = (table: unknown) => {
    tableRef.current = table;
  };

  const setDocumentsInTable = (documents: TDocument[], indexType?: TProjectIndexType) => {
    const sortedRootDocuments = sortDocuments(documents, indexType === "non_vdr_index");

    sortedRootDocuments.forEach((rootDoc) => {
      const sortedNestedDocuments = sortDocuments(rootDoc.nestedDocuments || [], indexType === "non_vdr_index");
      rootDoc.nestedDocuments = sortedNestedDocuments;
    });
    setTableData(sortedRootDocuments);
  };

  const fetchRootDocuments = async (projectId: string): Promise<TDocument[]> => {
    const rootDocuments = await http.getIndexDocument(projectId, { type: DocumentHostType.NON_HOSTED });
    return rootDocuments.filter((doc) => !doc.syncStatuses || !doc.syncStatuses.includes(TChangeTrackerStatus.DELETED));
  };

  const fetchNestedDocuments = async (rootDoc: TDocument): Promise<TDocument[]> => {
    const nestedDocuments = await http.getExpandAll(rootDoc.id);
    const filteredNestedDocuments = nestedDocuments.filter(
      (doc) => !doc.syncStatuses || !doc.syncStatuses.includes(TChangeTrackerStatus.DELETED),
    );

    rootDoc.nestedDocuments = filteredNestedDocuments;
    return filteredNestedDocuments;
  };

  const updateRootDocsWithUniqueReviewers = (rootDocs: TDocument[]): void => {
    rootDocs.forEach((rootDoc) => {
      const uniqueReviewers = new Map();
      if (rootDoc.type === TDocType.Folder) {
        rootDoc.nestedDocuments?.forEach((nestedDoc) => {
          if (nestedDoc.type === TDocType.Folder) return;
          nestedDoc.reviewers.forEach((reviewer) => {
            uniqueReviewers.set(reviewer.userId, reviewer);
          });
        });
        rootDoc.uniqueReviewers = Array.from(uniqueReviewers.values());
      }
    });
  };

  const syncTableDataAndSubRows = () => {
    setSubRows((prevSubRows) => {
      const updatedSubRows = prevSubRows.map((subRow) => {
        const updatedSubDocuments = subRow.subRows.map((doc) => {
          let updatedDoc: TDocument | undefined;

          tableDataRef.current.forEach((rootDoc) => {
            if (rootDoc.id === doc.id) {
              updatedDoc = rootDoc;
            } else {
              const nestedDoc = rootDoc.nestedDocuments?.find((nestedDoc) => nestedDoc.id === doc.id);
              if (nestedDoc) {
                updatedDoc = nestedDoc;
              }
            }
          });

          return updatedDoc || doc;
        });

        return {
          ...subRow,
          subRows: updatedSubDocuments,
        };
      });

      return updatedSubRows;
    });
  };

  const getSubRows = (id: string) => {
    let document: TDocument | undefined;

    for (const rootDoc of tableData) {
      if (rootDoc.id === id) {
        document = rootDoc;
        break;
      }

      const nestedDocument = rootDoc.nestedDocuments?.find((nestedDoc) => nestedDoc.id === id);
      if (nestedDocument) {
        document = nestedDocument;
        break;
      }
    }

    let parentRootDocument;

    if (document?.parentDocId === null) {
      parentRootDocument = document;
    } else {
      parentRootDocument = tableData.find(
        (rootDoc) => rootDoc.nestedDocuments?.some((nestedDoc) => nestedDoc.id === document?.id),
      );
    }

    const nestedDocuments = parentRootDocument?.nestedDocuments?.filter(
      (resultDocument) => resultDocument.parentDocId === document?.docId,
    );

    const sortedDocuments = sortDocuments(nestedDocuments || []);

    if (sortedDocuments) {
      const latestRowData: TDocumentRows = {
        id: id,
        subRows: sortedDocuments,
      };
      setSubRows((preValue) => [...preValue, latestRowData]);
    }
  };

  const updateDocument = (documentId: string, updatedData: TDocument) => {
    setSubRows((prevState) =>
      prevState.map((subRow: TDocumentRows) => {
        const updatedSubRows = subRow.subRows.map((rowInfo: TDocument) => {
          if (rowInfo.id === documentId) return updatedData;
          return rowInfo;
        });
        return { id: subRow.id, subRows: updatedSubRows };
      }),
    );
  };

  const updateRootDocument = (documentId: string, updatedData: TDocument) => {
    const updatedRootData = tableDataRef.current.map((row) => {
      if (row.id === documentId) return updatedData;
      return row;
    });
    setTableData(updatedRootData);
  };

  const updateRow = (document: TDocument) => {
    const isRootDocument = document.type === TDocType.Document && document.parentDocId === null;
    const isMyDocument = tableDataRef.current.find((rootDocument) => rootDocument.id === document.id);
    if (isRootDocument || isMyDocument) {
      updateRootDocument(document.id, document);
      return;
    }
    updateDocument(document.id, document);
  };

  const updateRowData = async (documentInfo: { document?: TDocument; documentId?: string }) => {
    const { document, documentId } = documentInfo;
    const rootDocument = tableDataRef.current.find(
      (rootDocument) => rootDocument.id === document?.id || rootDocument.id === documentId,
    );
    const subDocument = subRows.find((subRow: TDocumentRows) => {
      return subRow.subRows.find((rowInfo: TDocument) => rowInfo.id === documentId || rowInfo.id === document?.id);
    });
    const isDocumentExisted = rootDocument || subDocument;
    if (documentId && isDocumentExisted) {
      try {
        const response = await http.getDocument(documentId);
        updateRow(response);

        setTableData((prevTableData) => {
          const updatedTableData = prevTableData.map((rootDoc) => {
            return {
              ...rootDoc,
              nestedDocuments: rootDoc.nestedDocuments?.map((nestedDoc) => {
                if (nestedDoc.id === response.id) return response;
                return nestedDoc;
              }),
            };
          });

          updateRootDocsWithUniqueReviewers(updatedTableData);

          return updatedTableData;
        });
      } catch (err: unknown) {
        if (err instanceof Error) {
          errorToast(err.message);
        }
      }
    }
    if (document && isDocumentExisted) {
      updateRow(document);
    }
  };

  const updateSubRows = (document: TDocument, allDocuments: TDocumentList) => {
    setSubRows((preValue) => {
      const isAlreadyExisted = preValue.find((subRow) => subRow.id === document.id);
      if (!isAlreadyExisted) {
        const subRows = allDocuments.filter((resultDocument) => resultDocument.parentDocId === document.docId);
        const latestRowData: TDocumentRows = {
          id: document.id,
          subRows,
        };
        return [...preValue, latestRowData];
      }
      return [...preValue];
    });
  };

  const expandRootDocument = async (document: TDocument) => {
    updateSubRows(document, document.nestedDocuments || []);
    document.nestedDocuments?.forEach((nestedDocument) => {
      if (nestedDocument.type === TDocType.Folder) {
        updateSubRows(nestedDocument, document.nestedDocuments || []);
      }
    });
  };

  const expandAllRows = () => {
    if (tableData.length > 0) {
      tableData.forEach((rootDoc) => {
        rootDoc.type === TDocType.Folder && expandRootDocument(rootDoc);
      });
    }

    (tableRef.current as Table<TDocument>).toggleAllRowsExpanded(true);
  };

  const getIndexDocument = async (indexType?: TProjectIndexType, withLoadingStatus: boolean = true) => {
    withLoadingStatus && setIsTableDataLoading(true);

    try {
      if (projectId) {
        const documents = await fetchRootDocuments(projectId);

        if (documents.length > 0) {
          await Promise.all(documents.map(fetchNestedDocuments));
        }
        if (lastActiveTabRef.current === selectedTab) {
          updateRootDocsWithUniqueReviewers(documents);
          setDocumentsInTable(documents, indexType);
          syncTableDataAndSubRows();
          withLoadingStatus && setIsTableDataLoading(false);
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        errorToast(err.message);
      } else {
        errorToast(String(err));
      }
      withLoadingStatus && setIsTableDataLoading(false);
    }
  };

  const getMyDocuments = async (projectId: string) => {
    setIsTableDataLoading(true);
    try {
      const response = await http.getMyDocuments(projectId);
      const filteredDocuments = response.filter((doc) => {
        if (doc.docStatus === TDocStatus.EXCLUDED) return false;
        return !doc.syncStatuses || !doc.syncStatuses.includes(TChangeTrackerStatus.DELETED);
      });
      if (lastActiveTabRef.current === selectedTab) {
        setDocumentsInTable(filteredDocuments, project?.indexType);
        setIsTableDataLoading(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        errorToast(err.message);
      } else {
        errorToast(String(err));
      }
      setIsTableDataLoading(false);
    }
  };

  const getReviewDocuments = async (projectId: string) => {
    setIsTableDataLoading(true);

    try {
      const response = await http.getReviewDocuments(projectId);
      const filteredDocuments = response.filter(
        (doc) => !doc.syncStatuses || !doc.syncStatuses.includes(TChangeTrackerStatus.DELETED),
      );
      if (lastActiveTabRef.current === selectedTab) {
        setDocumentsInTable(filteredDocuments, project?.indexType);
        setIsTableDataLoading(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        errorToast(err.message);
      } else {
        errorToast(String(err));
      }
      setIsTableDataLoading(false);
    }
  };

  const collapseExpandedRows = () => {
    setExpandedRows({});
  };

  useEffect(() => {
    lastActiveTabRef.current = selectedTab;
    if (projectId && selectedTab === TDocumentTab.ALL_DOCUMENTS) {
      getIndexDocument(project?.indexType, true);
    }
    if (projectId && selectedTab === TDocumentTab.MY_DOCUMENTS) {
      getMyDocuments(projectId);
    }
    if (projectId && selectedTab === TDocumentTab.CHECKLISTS_FOR_SENIOR_REVIEW) {
      getReviewDocuments(projectId);
    }
  }, [projectId, selectedTab]);

  useEffect(() => {
    collapseExpandedRows();
  }, [projectId]);

  return (
    <IndexTableContext.Provider
      value={{
        setTableData,
        tableData,
        subRows,
        updateRowData,
        setSubRows,
        getSubRows,
        expandAllRows,
        expandedRows,
        setExpandedRows,
        collapseExpandedRows,
        isDataLoading,
        setIsTableDataLoading,
        getIndexDocument,
        setDocumentsInTable,
        updateDocument,
        lastInteractedDocumentID,
        setLastInteractedDocumentID,
        setTableRef,
      }}
    >
      {children}
    </IndexTableContext.Provider>
  );
};
