export const projectRoutes = [
  "/projects/issues/[id]",
  "/projects/[id]/documents",
  "/projects/[id]",
  "/projects/people/[id]",
  "/projects/edit/[id]",
  "/projects",
  "/projects/create",
  "/projects/[id]/documents/[documentid]/checklist",
  "/projects/[id]/documents/[documentid]/checklist/create",
  "/projects/[id]/documents/[documentid]/checklist/[checklistid]",
  "/organisations/[id]/templates",
  "/organisations/[id]/templates/create",
  "/projects/[id]/documents/[documentid]",
];

export enum PlatformAdminRoutes {
  ORGANIZATIONS = "/organisations",
  PLATFORM_ANALYTICS = "/organisations/analytics",
  CREATE_ORGANISATION = "/organisations/create",
  EDIT_ORGANISATION = "/organisations/edit/[id]",
  ORGANISATION_USERS = "/organisations/users/[id]",
  ORGANISATION_TEMPLATES = "/organisations/[id]/templates/portal-templates",
  ORGANISATION_EXPORT_TEMPLATES = "/organisations/[id]/export-templates/portal-export-templates",
  ORGANISATION_FEATURE_FLAGS = "/organisations/[id]/templates/feature-flags",
  ORGANISATION_TEMPLATES_UPLOAD = "/organisations/[id]/templates/upload",
  ORGANISATION_TEMPLATES_CREATE = "/organisations/[id]/templates/create",
  ORGANISATION_TEMPLATES_EDIT = "/organisations/[id]/templates/[templateid]/edit",
  ORGANISATION_EXPORT_TEMPLATES_UPLOAD = "/organisations/[id]/export-templates/upload",
  ORGANISATION_EXPORT_TEMPLATES_EDIT = "/organisations/[id]/export-templates/edit/[templateid]",
  ORGANISATION_SPECIAL_OPS = "/organisations/[id]/templates/special-ops",
}

const routes = {
  PLATFORM_ADMIN: [
    PlatformAdminRoutes.ORGANIZATIONS,
    PlatformAdminRoutes.PLATFORM_ANALYTICS,
    PlatformAdminRoutes.CREATE_ORGANISATION,
    PlatformAdminRoutes.EDIT_ORGANISATION,
    PlatformAdminRoutes.ORGANISATION_USERS,
    PlatformAdminRoutes.ORGANISATION_TEMPLATES,
    PlatformAdminRoutes.ORGANISATION_EXPORT_TEMPLATES,
    PlatformAdminRoutes.ORGANISATION_FEATURE_FLAGS,
    PlatformAdminRoutes.ORGANISATION_TEMPLATES_UPLOAD,
    PlatformAdminRoutes.ORGANISATION_TEMPLATES_CREATE,
    PlatformAdminRoutes.ORGANISATION_TEMPLATES_EDIT,
    PlatformAdminRoutes.ORGANISATION_EXPORT_TEMPLATES_UPLOAD,
    PlatformAdminRoutes.ORGANISATION_EXPORT_TEMPLATES_EDIT,
    PlatformAdminRoutes.ORGANISATION_SPECIAL_OPS,
  ],
  ORGANIZATION_USER: [...projectRoutes, "/projects/[id]/manage-templates"],
  ORGANIZATION_ADMIN: [...projectRoutes, "/projects/users", "/projects/[id]/manage-templates"],
  PROJECT_REVIEWER: [...projectRoutes],
  PROJECT_COORDINATOR: [...projectRoutes, "/projects/[id]/manage-templates"],
};

export default routes;
