import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import Avatar from "../Avatar";
import Button from "../Button/Button";
import CancelConfirmModel from "../CancelConfirmModel";
import CheckBox from "../CheckBox";
import DocumentStatusBadge from "../DocumentStatusBadge/DocumentStatusBadge";
import FormSuccessModal from "../FormSuccess";
import { Variant } from "../FormSuccess/FormSuccess";
import Icon, { IconName } from "../Icon/Icon";
import IssueComments from "../IssueComments";
import Modal from "../Modal";
import MultiSelect from "../MultiSelect/MultiSelect";
import RichTextArea from "../RichTextArea";
import SelectDropDown, { TSelect } from "../SelectDropDown/SelectDropDown";
import TextArea from "../TextArea/TextArea";
import TextField from "../TextField";

import * as styles from "./IssueForm.css";

import { useIssueCountContext } from "@web/context/IssueCountContext";
import { useIssueModalContext } from "@web/context/IssueModalContext";
import { useIssuesTableContext } from "@web/context/IssuesTableContext";
import { useProjectContext } from "@web/context/ProjectContext";
import { useSessionContext } from "@web/context/SessionContext";
import useModal from "@web/hooks/useModal";
import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import { neutral } from "@web/styles/palette.css";
import { TDocument } from "@web/types/document";
import { TFeatureFlags } from "@web/types/feature-flag";
import { EIssueStatus, TCreateIssueCommand, TIssue } from "@web/types/issue";
import { TReviewer, TUserBase } from "@web/types/user";
import { getRelatedDocuments } from "@web/utils/document";
import { getUserName } from "@web/utils/user";

type TModel = {
  content: React.ReactNode;
  title: string;
  icon: IconName;
  variant: Variant;
};

type TSeniorReviewer = TUserBase & {
  label: string;
  value: string;
};

type EModalIssueStatus = Record<Exclude<EIssueStatus & "DELETE", EIssueStatus.PENDING_APPROVAL>, TModel>;

const riskStatusOptions: TSelect[] = [
  {
    label: "Transaction Document Amendment",
    icon: "half-filled-circle",
    iconStyle: styles.riskStatusOptionIcon,
  },
  {
    label: "Conditions Precedent",
    icon: "half-filled-circle",
    iconStyle: styles.riskStatusOptionIcon,
  },
  {
    label: "Completion Deliverables",
    icon: "half-filled-circle",
    iconStyle: styles.riskStatusOptionIcon,
  },
  {
    label: "Post-closing Deliverables",
    icon: "half-filled-circle",
    iconStyle: styles.riskStatusOptionIcon,
  },
  {
    label: "Other",
    icon: "half-filled-circle",
    iconStyle: styles.riskStatusOptionIcon,
  },
  {
    label: "Noted",
    icon: "note-issue",
    iconStyle: styles.notedIcon,
  },
  {
    label: "Resolved",
    icon: "check-circle",
    iconStyle: styles.resolvedIcon,
  },
];

const riskRatingOptions: TSelect[] = [
  {
    label: "Low Risk",
    icon: "dashboard",
    iconStyle: styles.lowRiskIcon,
  },
  {
    label: "Medium Risk",
    icon: "dashboard",
    iconStyle: styles.mediumRiskIcon,
  },
  {
    label: "High Risk",
    icon: "dashboard",
    iconStyle: styles.highRiskIcon,
  },
];

const issueCategoryOptions: TSelect[] = [
  {
    label: "Corporate",
  },
  {
    label: "Contracts",
  },
  {
    label: "Data & Privacy",
  },
  {
    label: "ESG",
  },
  {
    label: "Employment",
  },
  {
    label: "Finance",
  },
  {
    label: "Insurance",
  },
  {
    label: "Intellectual Property",
  },
  {
    label: "Litigation",
  },
  {
    label: "Pensions",
  },
  {
    label: "Property",
  },
  {
    label: "Regulatory Compliance",
  },
  {
    label: "RFI",
  },
  {
    label: "Other",
  },
];

const getStatusModelContent = (issue: TIssue): EModalIssueStatus => ({
  [EIssueStatus.ACTION_REQUIRED]: {
    content: (
      <>
        <b>IS- {issue.issueNumber}</b> has been approved for <b>{issue.riskStatus}</b>. Please assign reviewers to
        resolve this issue
      </>
    ),
    title: 'Issue is Approved as "Action Required"',
    icon: "half-filled-circle",
    variant: "action",
  },
  [EIssueStatus.RESOLVED]: {
    content: (
      <>
        <b>IS- {issue.issueNumber}</b> is marked as &quot;Resolved&quot; and there are no further actions needed.
      </>
    ),
    title: "Issue is marked as Resolved",
    icon: "filled-check-circle",
    variant: "green",
  },
  [EIssueStatus.NOTED]: {
    content: (
      <>
        <b>IS- {issue.issueNumber}</b> is marked as &quot;Noted&quot; and there are no further actions needed.
      </>
    ),
    title: "Issue is marked as Noted",
    icon: "note-issue",
    variant: "blue",
  },
  ["DELETE"]: {
    content: (
      <>
        <b>IS- {issue.issueNumber}</b> is deleted from the list and there are no further actions needed.
      </>
    ),
    title: "Issue is Deleted",
    icon: "filled-cancel-circle",
    variant: "caution",
  },
});

const IssueForm = ({ issue, document }: { issue?: TIssue; document?: TDocument }) => {
  const router = useRouter();
  const {
    formState: { isDirty },
    reset,
    getValues,
    clearErrors,
    trigger,
    watch,
  } = useFormContext();
  const { data } = useSession();
  const { error, success } = useToast();
  const { closeIssueModal } = useIssueModalContext();
  const { updateRow, deleteRow, addRow } = useIssuesTableContext();
  const { isCoordinator } = useProjectContext();
  const { isOpen: openSuccessModel, toggleModal: toggleSuccessModel } = useModal();
  const { setRaisedIssueDocumentId } = useIssueCountContext();
  const { isOpen: isConfirmCancelModal, toggleModal: toggleConfirmCancelModal } = useModal();
  const { isOpen: isOpenSeniorReivewSuccess, toggleModal: toggleSeniorReivewSuccess } = useModal();
  const { isOpen: isOpenStatusSuccess, toggleModal: toggleOpenStatusSuccess } = useModal();
  const { isFeatureFlagEnabled } = useSessionContext();

  const [saved, setSaved] = useState<boolean>(false);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(true);
  const [statusModelData, setStatusModelData] = useState<TModel>();
  const [seniorReviewers, setSeniorReviewers] = useState<TSeniorReviewer[]>([]);

  const user = data?.user as TUserBase;
  const userName = issue?.user ? getUserName(issue.user) : getUserName(user);
  const projectId = router.query.id as string;

  const isDocReviewer = issue?.reviewers?.some((reviewer: TReviewer) => reviewer.userId === user.id);
  const disableForm = issue && !issue?.isDraft && !isCoordinator && !isDocReviewer;
  const isNeedsApprove = issue?.id && !issue.isDraft && issue.status === EIssueStatus.PENDING_APPROVAL && !disableForm;
  const isRaisedIssue = !(!issue?.id || issue.isDraft);

  const assignSeniorReview = watch("assignSeniorReview");
  const seniorReview = watch("seniorReview");

  const disableRaiseIssue =
    (!issue && assignSeniorReview) || (issue && issue.isDraft && assignSeniorReview && seniorReview?.id !== user.id);

  const statusMapping: Record<string, EIssueStatus> = {
    ["Transaction Document Amendment"]: EIssueStatus.ACTION_REQUIRED,
    ["Conditions Precedent"]: EIssueStatus.ACTION_REQUIRED,
    ["Completion Deliverables"]: EIssueStatus.ACTION_REQUIRED,
    ["Post-closing Deliverables"]: EIssueStatus.ACTION_REQUIRED,
    ["Other"]: EIssueStatus.ACTION_REQUIRED,
    ["Noted"]: EIssueStatus.NOTED,
    ["Resolved"]: EIssueStatus.RESOLVED,
  };

  const handleValidate = (cb: () => void) => async () => {
    const isValid = await trigger();

    if (isValid) {
      cb();
    }
  };

  const getFormValues = (): TCreateIssueCommand => {
    const values = getValues();
    const documentIds = values.relatedDocs.map((document: TDocument) => document.id);
    const data: TCreateIssueCommand = {
      riskStatus: values.riskStatus,
      riskRating: values.riskRating,
      issueCategory: values.issueCategory,
      issueTitle: values.issueTitle,
      issueDescription: values.issueDescription,
      recommendationTitle: values.recommendationTitle,
      recommendation: values.recommendation,
      relatedDocuments: documentIds.length > 0 ? documentIds : null,
      seniorReviewerId: values.assignSeniorReview && values.seniorReview ? values.seniorReview.id : null,
    };
    return data;
  };

  const getDocuments = getRelatedDocuments(`${projectId}`);

  const getSeniorReviewerName = () => {
    const values = getValues();
    return values?.seniorReview && getUserName(values?.seniorReview);
  };

  const handleSubmitIssue = async (isDraft?: boolean) => {
    try {
      const data: TCreateIssueCommand = {
        ...getFormValues(),
        isDraft: isDraft || false,
        status:
          issue?.id && issue?.status === EIssueStatus.PENDING_APPROVAL
            ? EIssueStatus.PENDING_APPROVAL
            : statusMapping[getFormValues().riskStatus],
      };
      if (!document && !issue?.id) {
        const response = await http.createProjectIssue(projectId, data);
        addRow(response);
        return response;
      }
      if (issue?.id) {
        const response = await http.updateIssue(`${issue?.id}`, data);
        return response;
      } else {
        const response = await http.createIssue(`${document?.id}`, data);
        setRaisedIssueDocumentId(`${document?.id}`);
        return response;
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      }
    }
  };

  const handleCloseDraft = () => {
    toggleConfirmCancelModal();
    closeAndClearForm();
  };

  const handleRaiseIssue = handleValidate(async () => {
    await handleSubmitIssue(false);
    setShowForm(false);
    toggleSuccessModel();
    issue && deleteRow(issue);
  });

  const cancelIssueConfirmModal = () => {
    closeAndClearForm();
    toggleSuccessModel();
  };

  const handleSaveIssue = handleValidate(async () => {
    const isDraft = issue?.id ? issue.isDraft : true;
    const resp = await handleSubmitIssue(isDraft);
    const isStatusUpdated = issue?.status !== resp?.status;
    resp?.id && updateRow({ ...resp, document: issue?.document }, true);
    if (issue?.id && isStatusUpdated) {
      const statusModel = resp && resp?.status && getStatusModelContent(issue)[resp?.status];
      setStatusModelData(statusModel);
      setShowForm(false);
      toggleOpenStatusSuccess();
    }
    if (disableRaiseIssue) {
      toggleSeniorReivewSuccess();
      setShowForm(false);
    } else {
      success(`Issue has been saved ${!issue?.id ? "in draft" : ""}`, undefined, false);
      (!isStatusUpdated || !issue?.id) && closeAndClearForm();
    }
  });

  const handleSaveDraftIssue = async () => {
    const isValid = await trigger();
    toggleConfirmCancelModal();

    if (isValid) {
      await handleSubmitIssue(true);
      success(`Issue has been saved in draft`, undefined, false);
      closeAndClearForm();
    }
  };
  const closeAndClearForm = async () => {
    clearErrors();
    reset();
    closeIssueModal();
  };

  const cancelIssueHandler = () => {
    if (issue?.id || assignSeniorReview) {
      closeAndClearForm();
      return;
    }
    toggleConfirmCancelModal();
  };

  const handleDeleteIssue = async () => {
    try {
      await http.deleteIssue(`${issue?.id}`);
      issue && deleteRow(issue);
      const statusModel = getStatusModelContent(issue)["DELETE"];
      setShowForm(false);
      setStatusModelData(statusModel);
      toggleOpenStatusSuccess();
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      }
    }
  };

  const handleApproveIssue = async () => {
    try {
      const data: TCreateIssueCommand = {
        ...getFormValues(),
        isDraft: false,
        status: statusMapping[getFormValues().riskStatus],
      };
      const response = await http.updateIssue(`${issue?.id}`, data);
      setShowForm(false);
      updateRow(response, true);
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      }
    } finally {
      closeAndClearForm();
    }
  };

  const toggleCommentsSection = () => setShowComments((showComments) => !showComments);

  useEffect(() => {
    if (isDirty) {
      setSaved(false);
    }
  }, [isDirty]);

  useEffect(() => {
    async function getSeniorReviewers() {
      let reviewers: TSeniorReviewer[] = [];
      let currentPage = 1;
      let totalPages;

      try {
        do {
          const queryParams = {
            page: currentPage,
            limit: 50,
          };

          const response = await http.getAllProjectUsers(projectId, queryParams);
          const data = response.items
            .filter((projectUser) => projectUser.id != user.id)
            .map((user) => ({
              ...user,
              value: user.id,
              label: `${user.email}`,
            }));

          reviewers = reviewers.concat(data);
          totalPages = response.meta.totalPages;
          currentPage++;
        } while (currentPage <= totalPages);
      } catch (err: unknown) {
        if (err instanceof Error) {
          error(err.message);
        }
      }

      setSeniorReviewers(reviewers);
    }

    getSeniorReviewers();
  }, []);

  const shouldShowGoToIssues = !router.pathname.includes("/issues");
  const shouldShowCommentSection = issue && showComments;
  const isRichTextAllowed = isFeatureFlagEnabled(TFeatureFlags.RICH_TEXT_SUPPORT);

  return (
    <>
      <Modal
        isOpen={showForm}
        align="center"
        className={[styles.modalContainer, shouldShowCommentSection && styles.modalContainerBig].join(" ")}
      >
        <div className={styles.root}>
          <div className={styles.headerFooter}>
            <div className={styles.heading}>
              {issue?.id ? "Issue" : "New Issue"}
              <div className={styles.buttonWrapper}>
                {issue && (
                  <Icon
                    name="comment-empty"
                    size={18}
                    color={showComments ? neutral.white : neutral.grey2}
                    className={[styles.icon, showComments && styles.activeIcon].join(" ")}
                    onClick={() => toggleCommentsSection()}
                  />
                )}
                {issue?.id && !disableForm && (
                  <Button
                    label="Delete Issue"
                    variant="delete"
                    icon="trash"
                    iconPosition="left"
                    iconSize={20}
                    onClick={() => handleDeleteIssue()}
                  />
                )}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>
                <Icon name="document" size={16} />
                <div>Document</div>
              </div>
              <div className={styles.value}>
                {document && <DocumentStatusBadge document={document} />}
                {document ? document?.name : "-"}
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.label}>
                <Icon name="white-user" size={16} />
                <div>Created By</div>
              </div>
              <div className={styles.value}>
                <Avatar name={userName} size={20} font={11} />
                <div>{userName}</div>
              </div>
            </div>
          </div>
          <div className={styles.formBody}>
            <div className={styles.form}>
              <div className={styles.formGroup}>
                <SelectDropDown
                  name="riskStatus"
                  labelIcon="eye"
                  label="Action Required"
                  required
                  options={riskStatusOptions}
                  optionsGroup="SELECT AN ACTION"
                  disabled={disableForm}
                />
              </div>
              <div className={styles.formGroup}>
                <SelectDropDown
                  name="riskRating"
                  required
                  labelIcon="dashboard"
                  label="Risk Rating"
                  options={riskRatingOptions}
                  disabled={disableForm}
                />
              </div>
              <div className={styles.formField}>
                <MultiSelect
                  variant="grey"
                  withPaginate={true}
                  loadPaginateOptions={getDocuments}
                  label="Related Documents (Optional)"
                  labelIcon="document"
                  placeholder="Select form Index Document"
                  async={true}
                  name="relatedDocs"
                  disabled={disableForm}
                />
              </div>
              <div className={styles.divider}></div>
              <div className={styles.subHeading}>ISSUE</div>
              <div className={styles.formGroup}>
                <SelectDropDown
                  name="issueCategory"
                  label="Issue Category"
                  optionsGroup="SELECT CATEGORY"
                  required
                  options={issueCategoryOptions}
                  disabled={disableForm}
                />
              </div>
              <TextField
                name="issueTitle"
                label="Issue Title"
                placeholder="Please select"
                required
                className={styles.formGroup}
                disabled={disableForm}
              />
              {isRichTextAllowed ? (
                <RichTextArea
                  name="issueDescription"
                  label="Issue Description"
                  placeholder="Write your description here..."
                  required
                  className={styles.formField}
                  disabled={disableForm}
                />
              ) : (
                <TextArea
                  name="issueDescription"
                  label="Issue Description"
                  placeholder="Write your description here..."
                  required
                  className={styles.formField}
                  disabled={disableForm}
                />
              )}
              <div className={styles.divider}></div>
              <div className={styles.subHeading}>RECOMMENDATION</div>
              <TextField
                name="recommendationTitle"
                label="Recommendation Title"
                placeholder="Type your title here..."
                required={false}
                className={styles.formField}
                disabled={disableForm}
              />
              {isRichTextAllowed ? (
                <RichTextArea
                  name="recommendation"
                  label="Recommendation"
                  placeholder="Write your description here..."
                  className={styles.formField}
                  disabled={disableForm}
                />
              ) : (
                <TextArea
                  name="recommendation"
                  label="Recommendation"
                  placeholder="Write your description here..."
                  className={styles.formField}
                  disabled={disableForm}
                />
              )}
              <div className={styles.divider}></div>
              <div className={styles.subHeading}>
                <Icon name="send" size={24} className={styles.sendIcon} />
                SENIOR REVIEW
              </div>
              <div className={styles.checkBoxContainer}>
                <CheckBox
                  label=""
                  name="assignSeniorReview"
                  value={assignSeniorReview}
                  disabled={issue && !issue.isDraft}
                />
                <div>
                  <div className={styles.checkBoxLabel}>Send for Senior Review (Optional)</div>
                  <div className={styles.checkBoxDescription}>
                    By selecting this option you will ask for senior review to raise the issue for you.
                  </div>
                </div>
              </div>
              {assignSeniorReview && (
                <div className={styles.formField}>
                  <MultiSelect
                    placeholder="Select Reviewer"
                    options={seniorReviewers}
                    name="seniorReview"
                    list="users"
                    isMulti={false}
                    disabled={disableForm || (issue && !issue?.isDraft)}
                  />
                </div>
              )}
            </div>
            <div className={styles.headerFooter}>
              <div className={styles.buttonGroup}>
                <Button label="Cancel" variant="white" type="button" onClick={cancelIssueHandler} />
                <div className={styles.buttonSubGroup}>
                  <Button
                    label={saved ? "Saved" : disableRaiseIssue ? "Save & Send" : "Save Changes"}
                    variant={saved ? "success" : isNeedsApprove || !isRaisedIssue ? "white" : "primary"}
                    iconSize={18}
                    icon={saved ? "check-circle" : null}
                    type="button"
                    onClick={handleSaveIssue}
                    disabled={disableForm}
                  />
                  {!isRaisedIssue && (
                    <Button
                      label="Raise Issue"
                      className={styles.raiseIssueButton}
                      icon="flag-empty"
                      type="button"
                      disabled={disableRaiseIssue || disableForm}
                      onClick={handleRaiseIssue}
                    />
                  )}
                  {isNeedsApprove && (
                    <Button
                      label="Approve Issue"
                      icon="filled-check-circle"
                      iconPosition="left"
                      onClick={handleApproveIssue}
                      disabled={disableForm}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {shouldShowCommentSection && (
          <IssueComments hideBackdrop issue={issue} isPlainBox={true} onClose={() => null} />
        )}
      </Modal>
      <CancelConfirmModel
        isOpen={isConfirmCancelModal}
        confirmHandler={handleSaveDraftIssue}
        cancelButtonLabel="Cancel Without Saving"
        saveButtonLabel="Save as Draft"
        content={
          <>
            Your changes will not be saved if you cancel. Click <b>Save as Draft</b> to retain your work in
            <b> &quot;Draft Issues&quot;.</b>
          </>
        }
        cancelHandler={handleCloseDraft}
      />
      <FormSuccessModal
        isOpen={openSuccessModel}
        content={
          <>
            Issue raised
            {document?.name &&
              ` for '
            ${document?.name}
            '`}
            . Until it is approved, it will be inactive. You can still view it on the <b>Issues Page.</b>
          </>
        }
        title="Issue has been raised!"
        buttonLabel={shouldShowGoToIssues ? "Go to Issues Page" : ""}
        onClick={() => {
          router.push(`/projects/issues/${projectId}`);
          cancelIssueConfirmModal();
        }}
        cancelButton={true}
        cancelHandler={cancelIssueConfirmModal}
        buttonVariant="primary"
        cancelButtonLabel="Close"
      />
      <FormSuccessModal
        isOpen={isOpenSeniorReivewSuccess}
        content={
          <>
            Your issue has been sent to <b>{getSeniorReviewerName()}</b> for them to edit or raise the issue.
          </>
        }
        title="Issue has been sent to Senior Review"
        buttonLabel="Close"
        onClick={() => {
          toggleSeniorReivewSuccess();
          closeAndClearForm();
        }}
        buttonVariant="white"
      />
      {statusModelData && (
        <FormSuccessModal
          isOpen={isOpenStatusSuccess}
          onClick={() => {
            closeAndClearForm();
            toggleOpenStatusSuccess();
          }}
          buttonLabel="Close"
          buttonVariant="white"
          {...statusModelData}
        />
      )}
    </>
  );
};

export default IssueForm;
