import { SubmitHandler } from "react-hook-form";

import Form from "../Form";
import Icon from "../Icon/Icon";
import Modal from "../Modal";
import TemplateFields from "../TemplateFields";

import * as styles from "./Template.css";

import useTemplateFields from "@web/hooks/useTemplateFields";
import { TTemplate } from "@web/types/template";

type TTemplateProps = {
  isOpen: boolean;
  closeModal: () => void;
  template?: TTemplate;
};

function Template({ isOpen, closeModal, template }: TTemplateProps) {
  const fields = template?.versions[0]?.data?.fields || null;
  const { initValues, schema } = useTemplateFields(fields);

  const handleSubmit: SubmitHandler<{ [key: string]: unknown }> = (values) => console.log(values);

  return (
    <Modal isOpen={isOpen}>
      <div className={styles.root}>
        <div className={styles.header}>
          <div>{template?.name}</div>
          <Icon name="close" size={20} className={styles.closeIcon} onClick={closeModal} data-testid="close" />
        </div>
        <div className={styles.templateBody}>
          {fields && (
            <Form schema={schema} values={initValues} onSubmit={handleSubmit}>
              <TemplateFields disabled={true} fields={fields} />
            </Form>
          )}
        </div>
      </div>
    </Modal>
  );
}

export default Template;
