import { TDBMetaBase } from "./api";

import { TOrganisation } from "@web/types/organisation";

export type TFeatureFlag = {
  id: string;
  name: string;
  tag: string;
} & TDBMetaBase;

export type TOrganisationFeatureFlag = {
  organisationId: string;
  organisation: TOrganisation;
  featureFlagId: string;
  featureFlag: TFeatureFlag;
  isEnabled: boolean;
};

export type TFeatureFlagWithStatus = {
  featureFlag: TFeatureFlag;
  isEnabled: boolean;
};

export enum TFeatureFlags {
  AI_FIELDS = "ai-fields",
  IGNORE_FINGERPRINT = "ignore-fingerprint",
  IGNORE_ALL_MODIFIED = "ignore-all-modified",
  RICH_TEXT_SUPPORT = "rich-text-support",
  DATASITE_INDEX = "datasite-index",
  INTRALINKS_INDEX = "intralinks-index",
}
