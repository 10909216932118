import React, { createContext, useContext, useState } from "react";

import IssueModal from "@web/components/IssueModal";
import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import { TApiError } from "@web/types/api";
import { TDocument } from "@web/types/document";
import { TIssue } from "@web/types/issue";

type TC = {
  openIssueModal: (id?: string, document?: TDocument) => void;
  closeIssueModal: () => void;
};

export const IssueModalContext = createContext<TC>({
  openIssueModal: () => null,
  closeIssueModal: () => null,
});

export const useIssueModalContext = (): TC => {
  return useContext(IssueModalContext);
};

export const IssueModalContextProvider = ({ children }: { children: React.ReactNode }) => {
  const toast = useToast();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [issue, setIssue] = useState<TIssue>();
  const [document, setDocument] = useState<TDocument>();

  const getIssueById = async (id: string) => {
    try {
      const issue = await http.getIssueById(id);
      setIssue(issue);
      setDocument(issue.document);
    } catch (err: unknown) {
      const error = err as TApiError;
      toast.error(error.message);
    }
  };

  const openIssueModal = (id?: string, document?: TDocument) => {
    setIsOpen(true);
    setDocument(document);
    if (id) {
      getIssueById(id);
    }
  };

  const closeIssueModal = () => {
    setIsOpen(false);
    setIssue(undefined);
    setDocument(undefined);
  };

  return (
    <IssueModalContext.Provider value={{ openIssueModal, closeIssueModal }}>
      {isOpen && <IssueModal issue={issue} document={document} />}
      {children}
    </IssueModalContext.Provider>
  );
};
