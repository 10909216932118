import axios from "axios";
import { getSession } from "next-auth/react";

import api from "./api";

function getHttpApi() {
  const instance = axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_URL,
    withCredentials: true,
    maxBodyLength: 1010000000,
  });

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      if (error?.response?.status === 401) {
        await getSession();
      } else if ([403, 400, 404, 409, 503].includes(error?.response?.status)) {
        const message = error.response.data.message;
        throw { message: Array.isArray(message) ? message.join(", ") : message };
      } else if (error?.response?.status === 413) {
        const message = error.response.statusText;
        throw { message: Array.isArray(message) ? message.join(", ") : message };
      } else if (!error?.status) {
        throw { message: "Network error, please try again" };
      }
      throw { error };
    },
  );

  return instance;
}

export const http = (function () {
  const http = getHttpApi();
  return api(http);
})();
