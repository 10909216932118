import { zodResolver } from "@hookform/resolvers/zod";
import React, { createContext, useContext } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as z from "zod";

type Schema = z.ZodObject<z.ZodRawShape>;

type FormProps = {
  schema: Schema;
  children: React.ReactNode;
  useFormProps?: Omit<Parameters<typeof useForm>[0], "resolver">;
  onSubmit: SubmitHandler<{ [key: string]: unknown }>;
  values?: { [key: string]: unknown };
};

const FormSchemaContext = createContext<Schema | null>(null);

export function useFormSchema(): Schema {
  const schema = useContext(FormSchemaContext);
  if (schema == null) {
    throw new Error("attempt to call useFormSchema outside of a <Form> component");
  }
  return schema;
}

function Form({ schema, children, useFormProps, onSubmit, values }: FormProps): React.ReactElement {
  const methods = useForm({
    mode: "onTouched",
    resolver: zodResolver(schema),
    ...useFormProps,
    values: values,
  });

  return (
    <FormSchemaContext.Provider value={schema}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>{children}</form>
      </FormProvider>
    </FormSchemaContext.Provider>
  );
}

export default Form;
