import "components/IssueForm/IssueForm.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"components/IssueForm/IssueForm.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA7VW247aMBB95yssrSq1D1klJATIPrVa+txfcGKTeNexU8ch0Kr/Xtu5YEOAZbsVEigzw/GZY8+JH/MXnIrQB79nAFQQIcLyBER+tQc+mEf650llWoJkkYBVtKr2+rmEe6/AJC9kAgLf/6RjiNQVhYcEbCk2RfrXQ0TgTBLOEpBx2pRMZ1IuEBae6AHUKjWnBAHwgGP9eZr9mT12zAKXmSam2VkVc1OhF1NQdxLhOyy2lLcJKAhCmFmwoYFVpVwk4GGOwkUUGjDOpFeTXzgx+hxD8kBVjHFRQjpG216klFOkg5QwPCp3rC2hyAnzUi4lL1UbcQd81gmkJGcekbisVRuYSSx0+KWpJdkevEwtqYIJqCuYYS/FssVOU5Fp6q2wOazUWeioXBUi+EchgpWzo4v7WQ6KTQo5AsfOni7xarlev7+VyPcvdHInebf75f/qfjUNbKajFRpHfz+djptxgeA4eMep8RRKnQlOjTZCBRw23Zh1sW5URiprQ+WUrjvXcJxrLxd6RAMrmZpkb0udA4257DwHwLhBHbcUZq8KtWEomTAd9KHn5MbsTzeBu+4JpQ6DXrLBOV3FttMbfKLEOz1kXFtyvZ8L58T1fOahwyef5nM88xfIWA2ljTobzOAMykXhyamWeC+hwNCpiqMfdlVhy7nZxM+R72QR2Tl7flZCbACMY+RkX+xsGC7Wzxsr+2png3m6XC6sLL2aLR3i37/FwVcry+xstlysosjK8iv6D0PqbIAZtlqJKSfOzXVzqW6/Lj/mLXFGxHXPnx/K47LFjwuKK65q3TmUg926SGWQZp+12sBTHNVof7GWqR1TC2KXhHRvST14Xz3Y9jhA/XPWiFqrVHEymMBwMYOINPWI00Xti9q5ZTaGgWOrg/hjze4dbzbX4VpQ73LVp7TnuS3Uf1XRXxnHoB3MCgAA\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var activeIcon = 'gjebr3u';
export var buttonGroup = 'gjebr3f';
export var buttonSubGroup = 'gjebr3g';
export var buttonWrapper = 'gjebr3v';
export var checkBoxContainer = 'gjebr3o';
export var checkBoxDescription = 'gjebr3q';
export var checkBoxLabel = 'gjebr3p';
export var deletedOption = 'gjebr3h';
export var divider = 'gjebr3c';
export var field = 'gjebr37';
export var form = 'gjebr38';
export var formBody = 'gjebr32';
export var formField = 'gjebr3b';
export var formGroup = 'gjebr3a';
export var headerFooter = 'gjebr31';
export var heading = 'gjebr33';
export var highRiskIcon = 'gjebr3n';
export var icon = 'gjebr3t';
export var item = 'gjebr35';
export var label = 'gjebr34';
export var lowRiskIcon = 'gjebr3l';
export var mediumRiskIcon = 'gjebr3m';
export var modalContainer = 'gjebr3r';
export var modalContainerBig = 'gjebr3s';
export var notedIcon = 'gjebr3j';
export var raiseIssueButton = 'gjebr3w';
export var resolvedIcon = 'gjebr3k';
export var riskStatusOptionIcon = 'gjebr3i';
export var root = 'gjebr30';
export var row = 'gjebr39';
export var sendIcon = 'gjebr3e';
export var subHeading = 'gjebr3d';
export var value = 'gjebr36';