import React, { useEffect, useRef, useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";

import { useFormSchema } from "../Form/Form";

import * as styles from "./TextArea.css";

import cx from "@web/utils/cx";

export type TextAreaProps = {
  label: string;
  name: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  resizable?: boolean;
};

function TextArea({
  label,
  name,
  placeholder,
  required = false,
  className,
  disabled = false,
  resizable = false,
}: TextAreaProps): React.ReactElement {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const schema = useFormSchema();
  const [height, setHeight] = useState("auto");
  const textInputRef = useRef<HTMLTextAreaElement | null>(null);
  const error = errors[name] as FieldError | undefined;
  const isRequired = required != null ? required : !schema.shape[name].isOptional;
  const requiredEl = isRequired ? <span className={styles.required}>*</span> : null;

  const adjustHeight = () => {
    if (textInputRef.current) {
      textInputRef.current.style.height = "auto";
      textInputRef.current.style.height = `${textInputRef.current.scrollHeight}px`;
      setHeight(`${textInputRef.current.scrollHeight}px`);
    }
  };

  const { onChange, ref: formRef, ...registerAttributes } = register(name);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
    if (resizable) {
      adjustHeight();
    }
  };

  const assignRef = (ele: HTMLTextAreaElement | null) => {
    formRef(ele);
    textInputRef.current = ele;
  };

  const additionalAttributes = resizable
    ? {
        style: { height, overflow: "hidden" },
      }
    : {};

  useEffect(() => {
    if (resizable) {
      adjustHeight();
    }
  }, []);

  return (
    <div className={[styles.root, className].join(" ")}>
      <div className={styles.label}>
        {label}
        {requiredEl}
      </div>
      <textarea
        id={name}
        className={cx(styles, {
          textarea: true,
          error: error != null,
        })}
        placeholder={placeholder}
        disabled={disabled}
        {...registerAttributes}
        {...additionalAttributes}
        onChange={handleChange}
        ref={assignRef}
      ></textarea>
      {error != null ? <div className={styles.errors}>{error.message}</div> : null}
    </div>
  );
}

export default TextArea;
