import "components/CommentBox/CommentBox.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"components/CommentBox/CommentBox.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VV226jMBB971dYqirtSuuIaxLo6+6HGGyIG2Oztmkuq/77GhsITkjVC4lQmBnmzDmemazwIZOv5wD8ewAAU9UydMpBxcjx2RjOkHJMjjkI+6cDxXqXgzgKWuvtoyCmkpSaCp6DUrCu4b0HMVpzSDVplEsGlUZS964Clftaio7jHDxW9ppyqZ2kfJ+D4Pnh7WHlKgttZa1Q1IFU9Ehw/4ak9U6bWFdLIbQWzfSoRTtz9ZkRFoccwHDdHoG9xam5mRAg6wL9CH6B4bsKsp8z/GhZmZdOaVqdYCm4JtzUoVpUElgQfSDkVoPSxBB5MSvCKvOOlkSXuxlabNGMkEIaeQJ7WXkMDFT0THIQJYP61qRPzNi4kA1ik/VAnDaFYFYqRjmBu8E4xk6YicVsKIfDAfc/B16zsHRZiBoZpbeupEVuFzobst1k2RWd8ON0EqeFxya00FOR6+UiW4Qx5fVFvMEAbZ+EkW8ce2m0f6nPrS59o70jTCEkJvKCZ5pRCUYxeCTr/jMjtrHEXNKhr/1xnCK3l8h0KfDuCPqa+Ekzm3RIFAbBU/9Cg2RNuT+uGaC87bQNH+hJhGmnblKi5aO6MzaOeexlKJYzfOG4ppSlN38RjtMkvm7Y9bca9uq0sAe4PCHR9yZk7QESCyheiaxYvxFRp8Womt3zU2T1gdLmWnyCdQVoU9+21OR/z7e731uj2RulcpNuk2SWgHq8RvfiUlpYNWPbuzFJFvi7rTvBvczrHSj5atRg9ZfZjYsMnvwkv3FVzLec+2e7gSCYauHyN+g4EUuHPTE2BTTDpEopGLvK0FHGYGNm0swVNNrsPSXjOM1+/7ELv5OqN7WCuvl9+w+tEfadZwgAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var backdrop = 'dw9rvzj';
export var button = 'dw9rvz4';
export var commentContainer = 'dw9rvz7 dw9rvz6';
export var commentHeader = 'dw9rvzc';
export var commentHeaderContainer = 'dw9rvza';
export var commentSubHeaderContainer = 'dw9rvzb';
export var commentTextContainer = 'dw9rvzf';
export var commentTime = 'dw9rvzd';
export var commentsContainer = 'dw9rvze';
export var container = 'dw9rvz6';
export var documentContainer = 'dw9rvz5';
export var editorContainer = 'dw9rvzg';
export var editorError = 'dw9rvzh';
export var fixedPosition = 'dw9rvz1';
export var footerContainer = 'dw9rvz8 dw9rvz6';
export var header = 'dw9rvz3';
export var headerContainer = 'dw9rvz2';
export var inputErrorMsg = 'dw9rvzi';
export var root = 'dw9rvz0';
export var textField = 'dw9rvz9';