import * as styles from "./CancelConfirmModel.css";

import Button, { ButtonVariant } from "@web/components/Button/Button";
import { IconName } from "@web/components/Icon/Icon";
import Modal from "@web/components/Modal/Modal";

const CancelConfirmModel = ({
  isOpen,
  confirmHandler,
  cancelHandler,
  cancelButtonLabel,
  saveButtonLabel,
  content,
  buttonIcon,
  confirmButtonVariant,
  centeredButtons = false,
  heading,
}: {
  isOpen: boolean;
  confirmHandler?: () => void;
  cancelHandler: () => void;
  cancelButtonLabel: string;
  saveButtonLabel: string;
  content: React.ReactNode | string;
  buttonIcon?: IconName;
  confirmButtonVariant?: ButtonVariant;
  centeredButtons?: boolean;
  heading?: string;
}) => {
  return (
    <Modal isOpen={isOpen} className={styles.root}>
      <h6 className={styles.header}>{heading ? heading : "Are you sure?"}</h6>
      <p className={styles.description}>{content}</p>
      <div className={styles.divider}></div>
      <div className={[styles.buttonsContainer, centeredButtons && styles.centerButtons].join(" ")}>
        <Button label={cancelButtonLabel} variant="white" className={styles.cancelButton} onClick={cancelHandler} />
        {confirmHandler ? (
          <Button
            icon={buttonIcon}
            iconSize={20}
            iconPosition="left"
            label={saveButtonLabel}
            onClick={confirmHandler}
            variant={confirmButtonVariant}
          />
        ) : null}
      </div>
    </Modal>
  );
};

export default CancelConfirmModel;
