import { ExpandedState } from "@tanstack/react-table";
import { useRouter } from "next/router";
import React, { createContext, Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from "react";

import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import { TApiError } from "@web/types/api";
import { TDocType } from "@web/types/document";
import { TIssue, TIssuesTab } from "@web/types/issue";

type TC = {
  tableData: TIssue[];
  getIssues: (category: string) => void;
  getCategories: () => void;
  selectedTab: TIssuesTab;
  handleTabChange: (tab: TIssuesTab) => void;
  updateRow: (issue: TIssue, api?: boolean) => void;
  deleteRow: (issue: TIssue) => void;
  expandAll: boolean;
  setExpandAll: Dispatch<SetStateAction<boolean>>;
  addRow: (issue: TIssue) => void;
  expandedRows: ExpandedState;
  setExpandedRows: Dispatch<SetStateAction<ExpandedState>>;
};

export const IssuesTableContext = createContext<TC>({
  tableData: [],
  getIssues: () => Promise.resolve(),
  getCategories: () => Promise.resolve(),
  selectedTab: "All Issues",
  handleTabChange: () => Promise.resolve(),
  updateRow: () => null,
  deleteRow: () => null,
  expandAll: true,
  setExpandAll: () => null,
  addRow: () => null,
  expandedRows: {},
  setExpandedRows: () => {},
});

export const useIssuesTableContext = (): TC => {
  return useContext(IssuesTableContext);
};

const initialIssue: TIssue = {
  id: "",
  issueTitle: "",
  issueDescription: "",
  recommendationTitle: "",
  recommendation: "",
  documentId: "",
  riskRating: "",
  isDraft: false,
  issueNumber: 0,
  riskStatus: "",
  seniorReviewerId: "",
  issueCategory: "",
  status: null,
  type: TDocType.Folder,
  subRows: [{} as TIssue],
};

export const IssuesTableContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const tab = (router.query?.tab as TIssuesTab) || "All Issues";

  const { error } = useToast();
  const [tableData, setTableData] = useState<TIssue[]>([]);
  const [selectedTab, setSelectedTab] = useState<TIssuesTab>(tab);
  const [expandAll, setExpandAll] = useState<boolean>(true);
  const [expandedRows, setExpandedRows] = useState<ExpandedState>({});
  const id = router.query.id as string;
  const tableDataRef = useRef<TIssue[]>(tableData);
  const selectedTabRef = useRef<TIssuesTab>(selectedTab);
  tableDataRef.current = tableData;
  selectedTabRef.current = selectedTab;

  const getCategories = async () => {
    try {
      const params = {
        isDraft: selectedTab === "Draft Issues",
        raisedByMe: selectedTab === "Raised by Me" || selectedTab === "Draft Issues",
      };
      const response = await (await http.getIssuesCategories(id, params)).slice();
      const data = response.map((category: string, index: number) => {
        return {
          ...initialIssue,
          issueNumber: index + 1,
          issueCategory: category,
          type: TDocType.Folder,
          subRows: [{} as TIssue],
        };
      });
      setTableData(data);
      data.map((issue) => {
        getIssues(issue.issueCategory);
      });
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      }
    }
  };

  const updateSubRows = (category: string, subRows: TIssue[]) => {
    setTableData((prevData) => {
      return prevData.map((issueCategory) => {
        if (issueCategory.issueCategory === category) {
          return { ...issueCategory, subRows: subRows };
        }
        return { ...issueCategory };
      });
    });
  };

  const addRow = (issue: TIssue) => {
    const canAddRow =
      (selectedTab == "Draft Issues" && issue.isDraft) ||
      ((selectedTab == "All Issues" || "Raised by Me") && !issue.isDraft);
    if (canAddRow) {
      const newIssue = { ...issue, type: TDocType.Document };
      setTableData((prevData) => {
        const isCategoryExisted = prevData.find((issueCategory) => issueCategory.issueCategory === issue.issueCategory);
        if (!isCategoryExisted) {
          const categoryData = {
            ...initialIssue,
            issueNumber: prevData.length + 1,
            issueCategory: issue.issueCategory,
            type: TDocType.Folder,
            subRows: [newIssue],
          };
          return [categoryData, ...prevData];
        }

        return prevData.map((issueCategory) => {
          if (issueCategory.issueCategory === issue.issueCategory) {
            const subRows = issueCategory.subRows || [];
            return { ...issueCategory, subRows: [...subRows, newIssue] };
          }
          return { ...issueCategory };
        });
      });
    }
  };

  const updateIssues = (response: TIssue[], category: string) => {
    const responseData = response.map((issue) => ({ ...issue, type: TDocType.Document }));
    updateSubRows(category, responseData);
  };

  const getIssues = async (category: string) => {
    try {
      if (selectedTabRef.current == "All Issues") {
        const params = { category: category, isDraft: false };
        const response = await http.getIssues(id, params);
        updateIssues(response, category);
      } else {
        const params = { category: category, isDraft: selectedTabRef.current === "Draft Issues" };
        const response = await http.getMyIssues(id, params);
        updateIssues(response, category);
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      }
    }
  };

  const deleteRow = async (issue: TIssue) => {
    setTableData((prevData) => {
      return prevData
        .map((issueData) => {
          if (issueData.issueCategory === issue.issueCategory) {
            const subRows = issueData?.subRows?.filter((subRow) => subRow.id !== issue.id) || [];
            return { ...issueData, subRows: subRows };
          }
          return { ...issueData };
        })
        .filter((issue) => issue.subRows && issue?.subRows.length > 0);
    });
  };

  const updateRow = async (issue: TIssue, api?: boolean) => {
    try {
      const updatedIssue = api ? await http.getIssueById(issue?.id) : issue;
      const row = tableDataRef.current.find((data) => data.issueCategory === issue.issueCategory);
      const updatedSubRows = row?.subRows?.map((subRow) => {
        if (subRow.id === issue.id) {
          return { ...updatedIssue, type: TDocType.Document };
        }
        return { ...subRow };
      });
      updatedSubRows && updateSubRows(issue.issueCategory, updatedSubRows);
    } catch (err: unknown) {
      const errorInfo = err as TApiError;
      error(errorInfo.message);
    }
  };

  const handleTabChange = async (tab: TIssuesTab) => {
    setSelectedTab(tab);
    setExpandAll(true);
  };

  useEffect(() => {
    if (id) {
      getCategories();
    }
  }, [id, selectedTab]);

  useEffect(() => {
    setSelectedTab(tab);
  }, [tab]);

  return (
    <IssuesTableContext.Provider
      value={{
        tableData,
        getIssues,
        getCategories,
        selectedTab,
        handleTabChange,
        updateRow,
        deleteRow,
        expandAll,
        setExpandAll,
        addRow,
        expandedRows,
        setExpandedRows,
      }}
    >
      {children}
    </IssuesTableContext.Provider>
  );
};
