import "react-datepicker/dist/react-datepicker.css";

import React, { useRef } from "react";
import DatePicker, { ReactDatePicker } from "react-datepicker";
import { Controller, FieldError, useFormContext } from "react-hook-form";

import { useFormSchema } from "../Form/Form";
import Icon from "../Icon/Icon";

import * as styles from "./DatePickerField.css";

import cx from "@web/utils/cx";

export type TextFieldProps = {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  format?: string;
  className?: string;
  disabled?: boolean;
  variant?: "grey";
};

function DatePickerField({
  name,
  label,
  placeholder,
  required,
  minDate,
  maxDate,
  format = "dd/MM/yyyy",
  className,
  disabled = false,
  variant,
}: TextFieldProps): React.ReactElement {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();
  const schema = useFormSchema();
  const error = errors[name] as FieldError | undefined;
  const isRequired = required != null ? required : !schema.shape[name].isOptional;
  const requiredEl = isRequired ? <span className={styles.required}>*</span> : null;

  const datepickerRef = useRef<ReactDatePicker | null>(null);

  const handleClickDatepickerIcon = () => {
    const datepickerElement = datepickerRef?.current;
    datepickerElement && datepickerElement.setFocus();
  };

  return (
    <div className={[styles.root, className].join(" ")}>
      <label>
        <div className={styles.label}>
          {label}
          {requiredEl}
        </div>
      </label>
      <Controller
        control={control}
        {...register(name)}
        render={({ field }) => (
          <div className={styles.inputWrapper}>
            <DatePicker
              placeholderText={placeholder}
              onChange={(date) => field.onChange(date)}
              selected={field.value ? new Date(field.value) : null}
              className={cx(styles, {
                input: true,
                greyVariant: variant === "grey",
                error: !!error,
              })}
              onBlur={field.onBlur}
              minDate={minDate}
              maxDate={maxDate}
              shouldCloseOnSelect
              dateFormat={format}
              ref={datepickerRef}
              disabled={disabled}
            />
            <div className={styles.dateIcon} onClick={handleClickDatepickerIcon}>
              <Icon name="date" size={24} />
            </div>
          </div>
        )}
      />
      {error != null ? <div className={styles.errors}>{error.message}</div> : null}
    </div>
  );
}

export default DatePickerField;
