import React, { createContext, useContext, useState } from "react";

import Template from "@web/components/Template";
import { TTemplate } from "@web/types/template";

type TC = {
  openTemplate: (template: TTemplate) => void;
};

export const TemplateContext = createContext<TC>({
  openTemplate: () => null,
});

export const useTemplateContext = (): TC => {
  return useContext(TemplateContext);
};

export const TemplateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [template, setTemplate] = useState<TTemplate>();

  const openTemplate = (template: TTemplate) => {
    setTemplate(template);
    setIsOpen(true);
  };

  const closeTemplate = () => {
    setIsOpen(false);
  };

  return (
    <TemplateContext.Provider value={{ openTemplate }}>
      <Template template={template} isOpen={isOpen} closeModal={closeTemplate} />
      {children}
    </TemplateContext.Provider>
  );
};
