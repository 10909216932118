import DOMPurify from "dompurify";
import dynamic from "next/dynamic";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";

import Avatar from "../Avatar";
import Button from "../Button/Button";
import Icon from "../Icon/Icon";
import Modal from "../Modal";

import * as styles from "./CommentBox.css";

import { getHtmlFromDeltaJson } from "@web/components/RichEditor/quill-utils";
import { useUserContext } from "@web/context/UserContext";
import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import { neutral, ui } from "@web/styles/palette.css";
import { TComment } from "@web/types/comment";
import { TDeltaStatic } from "@web/types/rich-editor";
import { TUserBase } from "@web/types/user";
import { getTimeAgoString } from "@web/utils/date";

const Editor = dynamic(() => import("@web/components/RichEditor/RichEditor").then((mod) => mod), { ssr: false });

export type CommentBoxProps = {
  title?: string;
  onClose(): void;
  comments?: TComment[];
  onPostComment(comment: TDeltaStatic): Promise<void>;
  isPlainBox?: boolean;
  hideBackdrop?: boolean;
  preventImages?: boolean;
};

interface CommentRefs {
  [key: string]: HTMLElement | null;
}

const CommentBox = ({
  title,
  onClose,
  comments = [],
  onPostComment,
  isPlainBox = false,
  hideBackdrop = false,
  preventImages = true,
}: CommentBoxProps) => {
  const router = useRouter();
  const { user } = useUserContext();
  const { error } = useToast();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [comment, setComment] = useState<TDeltaStatic | null>(null);
  const [disablePostComment, setDisablePostComment] = useState<boolean>(true);
  const [peoples, setPeoples] = useState<TUserBase[]>([]);
  const [peoplesLoaded, setPeoplesLoaded] = useState<boolean>(false);
  const [commentError, setCommentError] = useState<string>("");
  const commentRefs = useRef<CommentRefs>({});
  const [scrolledToReferedComment, setScrolledToReferedComment] = useState<boolean>(false);

  const projectId = router.query.id as string;

  const scrollToBottom = () => {
    messagesEndRef.current !== null &&
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "end" });
  };

  const scrollToComment = (commentId: string) => {
    const commentEl = commentRefs.current[commentId];
    if (commentEl) {
      commentEl.scrollIntoView({ behavior: "smooth", block: "start" });
      setScrolledToReferedComment(true);
    }
  };

  const scrollToReferedComment = () => {
    const commentId = router.query.commentRef as string;
    if (commentId?.length && !scrolledToReferedComment) {
      scrollToComment(commentId);
    } else {
      scrollToBottom();
    }
  };

  const handleCommentChange = (commentDelta: TDeltaStatic, htmlContent: string) => {
    const sanitiziedHtmlContent = DOMPurify.sanitize(htmlContent);

    let filteredDelta = commentDelta;
    // INFO: Prevent pasting images by removing any img tags from the delta
    if (preventImages) {
      filteredDelta = {
        ...commentDelta,
        ops: commentDelta.ops.filter((op) => {
          if (op.insert && typeof op.insert === "object" && op.insert.image) {
            return false;
          }
          return true;
        }),
      };
    }

    setComment(filteredDelta);
    const text = sanitiziedHtmlContent
      ?.replace(/<[^>]*(>|$)| |‌|»|«|>/g, " ")
      .replace(/\s+/g, " ")
      .trim();
    if (!text) {
      setDisablePostComment(true);
      setCommentError("");
    } else if (text.length > 5000) {
      setDisablePostComment(true);
      setCommentError("Exceeded 5000 characters limit");
    } else {
      setDisablePostComment(false);
      setCommentError("");
    }
  };

  const handlePostComment = () => {
    onPostComment(comment!);
    setComment(null);
  };

  useEffect(() => {
    if (comments.length) {
      setTimeout(scrollToReferedComment, 700);
    }
  }, [comments.length]);

  useEffect(() => {
    loadPeoples();
  }, []);

  const loadPeoples = async () => {
    try {
      const response = await http.getAllProjectUsers(projectId);
      setPeoples([...peoples, ...response.items]);
      setPeoplesLoaded(true);
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      }
    }
  };

  const peoplesNamesMapById = peoples.reduce(
    (peoplesNamesMapById, people) => {
      peoplesNamesMapById[people.id] = `${people.firstName} ${people.lastName}`;
      return peoplesNamesMapById;
    },
    {} as Record<TUserBase["id"], string>,
  );

  const renderCommentBox = () => {
    return (
      <div className={[styles.root, isPlainBox ? "" : styles.fixedPosition].join(" ")}>
        <div className={styles.container}>
          <div className={styles.headerContainer}>
            <h3 className={styles.header}>Comments</h3>
            {!isPlainBox ? (
              <Button label="Close" variant="white" onClick={() => onClose()} className={styles.button} />
            ) : null}
          </div>
          {title && (
            <div className={styles.documentContainer}>
              <Icon name="file" size={16} color={ui.secondary} />
              <span>{title}</span>
            </div>
          )}
        </div>
        <div className={styles.commentsContainer}>
          {comments.map((comment) => {
            const fullName = `${comment.commentedBy.firstName} ${comment.commentedBy.lastName}`;
            const avatarColor = user?.id === comment.commentedBy.id ? ui.primary : neutral.blue;
            return (
              <div
                className={styles.commentContainer}
                key={comment.id}
                ref={(el) => (commentRefs.current[comment.id] = el)}
              >
                <div className={styles.commentHeaderContainer}>
                  <Avatar
                    avatarText={fullName
                      .match(/\b([A-Za-z])/g)
                      ?.join("")
                      .toUpperCase()}
                    size={32}
                    backgroundColor={avatarColor}
                  />
                  <div className={styles.commentSubHeaderContainer}>
                    <div className={styles.commentHeader}>{fullName}</div>
                    <div className={styles.commentTime}>{getTimeAgoString(comment.createdAt ?? "")}</div>
                  </div>
                </div>
                <div
                  className={styles.commentTextContainer}
                  dangerouslySetInnerHTML={{ __html: getHtmlFromDeltaJson(comment.deltaJson, peoplesNamesMapById) }}
                ></div>
              </div>
            );
          })}
          <div ref={messagesEndRef} />
        </div>
        <div className={styles.footerContainer}>
          {peoplesLoaded && (
            <Editor
              users={peoples}
              inputClassName={[styles.editorContainer, commentError.length > 0 ? styles.editorError : ""].join(" ")}
              placeholder="Reply or alert others with @"
              content={comment}
              onChange={handleCommentChange}
            />
          )}
          {commentError !== "" ? <div className={styles.inputErrorMsg}>{commentError}</div> : null}
          <Button
            label="Enter"
            icon={"flat-send"}
            className={styles.button}
            onClick={handlePostComment}
            disabled={disablePostComment}
          />
        </div>
      </div>
    );
  };

  if (hideBackdrop) {
    return renderCommentBox();
  }

  return (
    <Modal isOpen={true} className={styles.backdrop}>
      {renderCommentBox()}
    </Modal>
  );
};

export default CommentBox;
