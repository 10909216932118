import { useSession } from "next-auth/react";
import React, { createContext, useContext } from "react";

import { TUser } from "@web/types/user";

type TC = {
  user?: TUser;
};

export const UserContext = createContext<TC>({
  user: undefined,
});

export const useUserContext = (): TC => {
  return useContext(UserContext);
};

export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { data } = useSession();
  const user = data?.user as TUser;

  return <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>;
};
