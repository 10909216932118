import { useState } from "react";
import { FieldError, useFormContext } from "react-hook-form";

import Icon, { IconName } from "../Icon/Icon";
import OutsideClickHandler from "../OutsideClickHandler/OutsideClickHandler";

import * as styles from "./SelectDropDown.css";

import cx from "@web/utils/cx";

export type TSelect = {
  label: string;
  icon?: IconName;
  iconStyle?: string;
};

type TSelectDropDownProps = {
  label: string;
  name: string;
  labelIcon?: IconName;
  placeholder?: string;
  options: TSelect[];
  optionsGroup?: string;
  disabled?: boolean;
  required?: boolean;
  variant?: "grey";
};

function SelectDropDown({
  label,
  name,
  options,
  labelIcon,
  placeholder,
  optionsGroup,
  disabled = false,
  required,
  variant,
}: TSelectDropDownProps) {
  const {
    register,
    setValue,
    clearErrors,
    formState: { errors },
  } = useFormContext();
  const [open, setOpen] = useState<boolean>(false);
  const requiredEl = required ? <span className={styles.required}>*</span> : null;

  const handleSelect = (value: string) => {
    toggleOpen();
    setValue(name, value, { shouldDirty: true });
    clearErrors(name);
  };

  const error = errors[name] as FieldError | undefined;

  const toggleOpen = () => setOpen((prev) => !prev);
  const placeHolder = placeholder ? placeholder : "Please select";

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setOpen(false);
      }}
    >
      <div className={styles.root}>
        <label className={styles.label}>
          {labelIcon && <Icon name={labelIcon} size={15} />}
          {label}
          {requiredEl}
        </label>
        <div className={styles.inputSection}>
          <div className={styles.input} onClick={() => !disabled && toggleOpen()}>
            <input
              className={cx(styles, {
                textInput: true,
                error: !!error,
                disabled: disabled,
                greyVariant: variant === "grey",
              })}
              placeholder={placeHolder}
              disabled={disabled}
              {...register(name)}
              readOnly
            />
            <div className={styles.placeholder}>{placeHolder}</div>
            <div className={styles.arrowIcon}>
              <Icon name="down" size={18} />
            </div>
          </div>
          <ul className={cx(styles, { selectList: true, hideList: !open })}>
            {optionsGroup && (
              <li className={cx(styles, { listItem: true, placeholderOption: true })}>{optionsGroup}</li>
            )}
            {options.map((option, index) => (
              <li key={index} className={styles.listItem} onClick={() => handleSelect(option.label)}>
                {option.icon && <Icon name={option.icon} size={17} className={option.iconStyle} />}
                <div className={styles.optionText}>{option.label}</div>
              </li>
            ))}
          </ul>
        </div>
        {error != null ? <div className={styles.errors}>{error.message}</div> : null}
      </div>
    </OutsideClickHandler>
  );
}

export default SelectDropDown;
