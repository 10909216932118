import { ExpandedState, Table } from "@tanstack/react-table";
import { useRouter } from "next/router";
import React, {
  ChangeEvent,
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useDocumentTabsContext } from "./DocumentTabsContext";

import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import { DocumentHostType, TDocType, TDocument, TDocumentList, TDocumentRows } from "@web/types/document";
import { TCreateFolderData } from "@web/types/folder";
import { sortDocuments } from "@web/utils/document";

type TC = {
  onFileUpload: (event: ChangeEvent<HTMLInputElement>, documentId?: string) => Promise<void>;
  onCreateFolder: (data: TCreateFolderData) => Promise<void>;
  onDeleteDocument: (folderId: string) => Promise<void>;
  updateDocument: (documentId: string, updatedData: TDocument) => void;
  getSubRows: (id: string) => void;
  tableData: TDocument[];
  subRows: TDocumentRows[];
  updateRowData: (documentInfo: { document?: TDocument | undefined; documentId?: string | undefined }) => Promise<void>;
  expandAllRows: () => void;
  expandedRows: ExpandedState;
  setExpandedRows: Dispatch<SetStateAction<ExpandedState>>;
  collapseExpandedRows: () => void;
  getIndexDocument: () => void;
  setTableRef: (table: unknown) => void;
  isOtherDocumentsUploading: boolean;
};

export const OtherDocumentsContext = createContext<TC>({
  onFileUpload: () => Promise.resolve(),
  onCreateFolder: () => Promise.resolve(),
  onDeleteDocument: () => Promise.resolve(),
  updateDocument: () => undefined,
  getSubRows: () => [],
  tableData: [],
  subRows: [],
  updateRowData: () => Promise.resolve(),
  expandAllRows: () => undefined,
  expandedRows: {},
  setExpandedRows: () => {},
  collapseExpandedRows: () => {},
  getIndexDocument: () => {},
  setTableRef: () => {},
  isOtherDocumentsUploading: false,
});

export const useOtherDocumentsContext = (): TC => {
  return useContext(OtherDocumentsContext);
};

export const OtherDocumentsContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { error: errorToast, success: succesToast } = useToast();
  const { selectedSubTab } = useDocumentTabsContext();

  const [expandedRows, setExpandedRows] = useState<ExpandedState>({});
  const [tableData, setTableData] = useState<TDocument[]>([]);
  const [subRows, setSubRows] = useState<TDocumentRows[]>([]);
  const [isOtherDocumentsUploading, setIsOtherDocumentsUploading] = useState(false);

  const tableDataRef = useRef<TDocumentList>(tableData);
  tableDataRef.current = tableData;

  const tableRef = useRef<unknown | null>(null);

  const setTableRef = (table: unknown) => {
    tableRef.current = table;
  };

  const projectId = router.query.id as string;
  const getDocumentByField = (key: "id" | "docId", value: string): TDocument => {
    let document = {} as TDocument;
    const checkAndAssignDocument = (doc: TDocument) => {
      if (doc[key] === value) {
        document = doc;
      }
    };
    tableData.forEach(checkAndAssignDocument);
    subRows.forEach((row) => row.subRows.forEach(checkAndAssignDocument));

    return document;
  };

  const getPath = (document: TDocument) => {
    if (!document.parentDocId) return `/${document.name}`;
    const parentPath: string = getPath(getDocumentByField("docId", document.parentDocId));
    return `${parentPath}/${document.name}`;
  };

  const onFileUpload = async (event: ChangeEvent<HTMLInputElement>, documentId?: string) => {
    try {
      let path = "/";
      const files = (event.target as HTMLInputElement).files;
      const file = files && files[0];
      if (!file || !projectId) return;

      if (documentId) {
        const document = getDocumentByField("id", documentId);
        path = getPath(document);
      }
      setIsOtherDocumentsUploading(true);
      const uploadedDocument = await http.uploadOtherDocument(projectId, file, path, documentId);
      const existedSubRows = subRows.find((subRow) => subRow.id === documentId);

      if (existedSubRows && documentId) {
        const subRow = {
          id: documentId,
          subRows: [...(existedSubRows?.subRows || []), uploadedDocument],
        };
        subRow &&
          setSubRows((preSubRows) =>
            preSubRows.map((prevSubRow) => {
              if (prevSubRow.id === subRow.id) {
                return subRow;
              }
              return prevSubRow;
            }),
          );
      } else {
        await getIndexDocument();
      }
      succesToast("File upload successful");
    } catch (err: unknown) {
      const errorObj = err as Error;
      if (errorObj && errorObj.message) {
        errorToast(`Failed to upload file - ${errorObj.message}`);
      } else {
        errorToast(`Failed to upload file`);
      }
    } finally {
      setIsOtherDocumentsUploading(false);
    }
  };

  // TODO: reuse this logic for IndexTableContext and OtherDocumentsContext
  const getSubRows = (id: string) => {
    let document: TDocument | undefined;

    for (const rootDoc of tableData) {
      if (rootDoc.id === id) {
        document = rootDoc;
        break;
      }

      const nestedDocument = rootDoc.nestedDocuments?.find((nestedDoc) => nestedDoc.id === id);
      if (nestedDocument) {
        document = nestedDocument;
        break;
      }
    }

    let parentRootDocument;

    if (document?.parentDocId === null) {
      parentRootDocument = document;
    } else {
      parentRootDocument = tableData.find(
        (rootDoc) => rootDoc.nestedDocuments?.some((nestedDoc) => nestedDoc.id === document?.id),
      );
    }

    const nestedDocuments = parentRootDocument?.nestedDocuments?.filter(
      (resultDocument) => resultDocument.parentDocId === document?.docId,
    );

    const sortedDocuments = sortDocuments(nestedDocuments || []);

    if (sortedDocuments) {
      const latestRowData: TDocumentRows = {
        id: id,
        subRows: sortedDocuments,
      };
      setSubRows((preValue) => [...preValue, latestRowData]);
    }
  };

  const onCreateFolder = async (data: TCreateFolderData) => {
    try {
      if (projectId) {
        const folder = await http.createFolder(projectId, data);
        if (data.parentDocId) {
          const existedSubRows = subRows.find((subRow) => subRow.id === data.parentDocId);
          const subRow = existedSubRows && {
            id: data.parentDocId,
            subRows: [...existedSubRows.subRows, folder],
          };
          subRow &&
            setSubRows((preSubRows) =>
              preSubRows.map((prevSubRow) => {
                if (prevSubRow.id === subRow.id) {
                  return subRow;
                }
                return prevSubRow;
              }),
            );
        } else {
          setTableData([...tableData, folder]);
        }
      }
    } catch (err) {
      errorToast("Failed to create folder");
    }
  };

  const onDeleteDocument = async (deleteDocumentId: string) => {
    try {
      if (projectId) {
        const deletedDocument = await http.deleteExternalDocument(projectId, deleteDocumentId);

        setTableData((prevDocuments) => prevDocuments.filter((document) => document.id !== deletedDocument.id));
        setSubRows((prevSubRows) => {
          return prevSubRows.map((prevSubRow) => {
            const updatedSubRows = prevSubRow.subRows.filter((subRow) => subRow.id !== deletedDocument.id);
            return {
              id: prevSubRow.id,
              subRows: updatedSubRows,
            };
          });
        });
      }
      succesToast("Delete successful");
    } catch (err) {
      const errorObj = err as Error;
      if (errorObj && errorObj.message) {
        errorToast(`Delete failed - ${errorObj.message}`);
      } else {
        errorToast(`Delete failed`);
      }
    }
  };

  const fetchRootDocuments = async (projectId: string) => {
    return await http.getIndexDocument(projectId, { type: DocumentHostType.HOSTED });
  };

  const updateRootDocument = (documentId: string, updatedData: TDocument) => {
    const updatedRootData = tableDataRef.current.map((row) => {
      if (row.id === documentId) return updatedData;
      return row;
    });
    setTableData(updatedRootData);
  };

  const updateRow = (document: TDocument) => {
    const isRootDocument = document.type === TDocType.Document && document.parentDocId === null;
    const isMyDocument = tableDataRef.current.find((rootDocument) => rootDocument.id === document.id);
    if (isRootDocument || isMyDocument) {
      updateRootDocument(document.id, document);
      return;
    }
    updateDocument(document.id, document);
  };

  const updateRowData = async (documentInfo: { document?: TDocument; documentId?: string }) => {
    const { document, documentId } = documentInfo;
    const rootDocument = tableDataRef.current.find(
      (rootDocument) => rootDocument.id === document?.id || rootDocument.id === documentId,
    );
    const subDocument = subRows.find((subRow: TDocumentRows) => {
      return subRow.subRows.find((rowInfo: TDocument) => rowInfo.id === documentId || rowInfo.id === document?.id);
    });
    const isDocumentExisted = rootDocument || subDocument;
    if (documentId && isDocumentExisted) {
      try {
        const response = await http.getDocument(documentId);
        updateRow(response);

        setTableData((prevTableData) => {
          const updatedTableData = prevTableData.map((rootDoc) => {
            return {
              ...rootDoc,
              nestedDocuments: rootDoc.nestedDocuments?.map((nestedDoc) => {
                if (nestedDoc.id === response.id) return response;
                return nestedDoc;
              }),
            };
          });

          updateRootDocsWithUniqueReviewers(updatedTableData);

          return updatedTableData;
        });
      } catch (err: unknown) {
        if (err instanceof Error) {
          errorToast(err.message);
        }
      }
    }
    if (document && isDocumentExisted) {
      updateRow(document);
    }
  };

  const updateDocument = (documentId: string, updatedData: TDocument) => {
    setSubRows((prevState) =>
      prevState.map((subRow: TDocumentRows) => {
        const updatedSubRows = subRow.subRows.map((rowInfo: TDocument) => {
          if (rowInfo.id === documentId) return updatedData;
          return rowInfo;
        });
        return { id: subRow.id, subRows: updatedSubRows };
      }),
    );
  };

  const fetchNestedDocuments = async (rootDoc: TDocument): Promise<TDocument[]> => {
    const nestedDocuments = await http.getExpandAll(rootDoc.id);
    const filteredNestedDocuments = nestedDocuments.filter((doc) => !doc.syncStatuses);
    rootDoc.nestedDocuments = filteredNestedDocuments;
    return filteredNestedDocuments;
  };

  const updateRootDocsWithUniqueReviewers = (rootDocs: TDocument[]): void => {
    rootDocs.forEach((rootDoc) => {
      const uniqueReviewers = new Map();
      if (rootDoc.type === TDocType.Folder) {
        rootDoc.nestedDocuments?.forEach((nestedDoc) => {
          if (nestedDoc.type === TDocType.Folder) return;
          nestedDoc.reviewers.forEach((reviewer) => {
            uniqueReviewers.set(reviewer.userId, reviewer);
          });
        });
        rootDoc.uniqueReviewers = Array.from(uniqueReviewers.values());
      }
    });
  };

  const setDocumentsInTable = (documents: TDocument[]) => {
    const sortedRootDocuments = sortDocuments(documents);

    sortedRootDocuments.forEach((rootDoc) => {
      const sortedNestedDocuments = sortDocuments(rootDoc.nestedDocuments || []);
      rootDoc.nestedDocuments = sortedNestedDocuments;
    });
    setTableData(sortedRootDocuments);
  };

  const syncTableDataAndSubRows = () => {
    setSubRows((prevSubRows) => {
      const updatedSubRows = prevSubRows.map((subRow) => {
        const updatedSubDocuments = subRow.subRows.map((doc) => {
          let updatedDoc: TDocument | undefined;

          tableDataRef.current.forEach((rootDoc) => {
            if (rootDoc.id === doc.id) {
              updatedDoc = rootDoc;
            } else {
              const nestedDoc = rootDoc.nestedDocuments?.find((nestedDoc) => nestedDoc.id === doc.id);
              if (nestedDoc) {
                updatedDoc = nestedDoc;
              }
            }
          });

          return updatedDoc || doc;
        });

        return {
          ...subRow,
          subRows: updatedSubDocuments,
        };
      });

      return updatedSubRows;
    });
  };

  const getIndexDocument = async () => {
    try {
      if (projectId) {
        const documents = await fetchRootDocuments(projectId);

        if (documents.length > 0) {
          await Promise.all(documents.map(fetchNestedDocuments));
        }

        updateRootDocsWithUniqueReviewers(documents);
        setDocumentsInTable(documents);
        syncTableDataAndSubRows();
      }
    } catch (err) {
      if (err instanceof Error) {
        errorToast(err.message);
      } else {
        errorToast(String(err));
      }
    }
  };

  const updateSubRows = (document: TDocument, allDocuments: TDocumentList) => {
    setSubRows((preValue) => {
      const isAlreadyExisted = preValue.find((subRow) => subRow.id === document.id);
      if (!isAlreadyExisted) {
        const subRows = allDocuments.filter((resultDocument) => resultDocument.parentDocId === document.docId);
        const latestRowData: TDocumentRows = {
          id: document.id,
          subRows,
        };
        return [...preValue, latestRowData];
      }
      return [...preValue];
    });
  };

  const expandRootDocument = async (document: TDocument) => {
    updateSubRows(document, document.nestedDocuments || []);
    document.nestedDocuments?.forEach((nestedDocument) => {
      if (nestedDocument.type === TDocType.Folder) {
        updateSubRows(nestedDocument, document.nestedDocuments || []);
      }
    });
  };

  const expandAllRows = () => {
    if (tableData.length > 0) {
      tableData.forEach((rootDoc) => {
        rootDoc.type === TDocType.Folder && expandRootDocument(rootDoc);
      });
    }

    (tableRef.current as Table<TDocument>).toggleAllRowsExpanded(true);
  };

  const collapseExpandedRows = () => {
    setExpandedRows({});
  };

  useEffect(() => {
    collapseExpandedRows();
  }, [projectId]);

  useEffect(() => {
    projectId && selectedSubTab === "Other Documents" && getIndexDocument();
  }, [projectId, selectedSubTab]);

  return (
    <OtherDocumentsContext.Provider
      value={{
        onFileUpload,
        tableData,
        subRows,
        updateRowData,
        onCreateFolder,
        onDeleteDocument,
        updateDocument,
        getSubRows,
        expandAllRows,
        expandedRows,
        setExpandedRows,
        collapseExpandedRows,
        getIndexDocument,
        setTableRef,
        isOtherDocumentsUploading,
      }}
    >
      {children}
    </OtherDocumentsContext.Provider>
  );
};
