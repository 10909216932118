import { useSession } from "next-auth/react";
import React, { createContext, useContext, useEffect, useState } from "react";

import usePagination from "@web/hooks/usePagination";
import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import { TApiError } from "@web/types/api";
import { TNotification } from "@web/types/notification";
import { EUserRoles } from "@web/types/user";

type TC = {
  notifications: TNotification[];
  unreadCount: number;
  deleteNotification: (notification: TNotification) => void;
  markAsRead: (notification: TNotification) => void;
  getNotofications: () => void;
  clearNotifications: () => void;
  hasMoreNotifications: boolean;
  loading: boolean;
};

export const NotificationContext = createContext<TC>({
  notifications: [],
  unreadCount: 0,
  deleteNotification: () => null,
  markAsRead: () => null,
  getNotofications: () => null,
  clearNotifications: () => null,
  hasMoreNotifications: false,
  loading: false,
});

export const useNotificationContext = (): TC => {
  return useContext(NotificationContext);
};

export const NotificationContextProvider = ({ children }: { children: React.ReactNode }) => {
  const toast = useToast();
  const { data: session } = useSession();

  const [notifications, setNotifications] = useState<TNotification[]>([]);
  const [unreadCount, setUnreadCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);
  const { pageNumber, resetPagination, hasMore, setPaginationData } = usePagination();

  const userRole = session?.user.role;

  const getNotofications = async () => {
    try {
      if (pageNumber === 1) {
        setLoading(true);
      }
      const data = await http.getNotifications({
        page: pageNumber,
        limit: 5,
      });
      setLoading(false);
      setNotifications([...notifications, ...data.items]);
      setPaginationData(data.meta);
    } catch (err: unknown) {
      setLoading(false);
      const error = err as TApiError;
      toast.error(error.message);
    }
  };

  const getUnreadNotoficationCount = async () => {
    try {
      const data = await http.getUnreadNotoficationCount();
      setUnreadCount(data.unread);
    } catch (err: unknown) {
      const error = err as TApiError;
      toast.error(error.message);
    }
  };

  const deleteNotification = async ({ id, isRead }: TNotification) => {
    try {
      await http.deleteNotification(id);
      const updatedNotificaions = notifications.filter((notification) => notification.id !== id);
      setNotifications(updatedNotificaions);
      if (!isRead) {
        setUnreadCount((count) => count - 1);
      }
    } catch (err: unknown) {
      const error = err as TApiError;
      toast.error(error.message);
    }
  };

  const markAsRead = async ({ id }: TNotification) => {
    try {
      await http.markAsReadNotification(id);
      setUnreadCount((count) => count - 1);
    } catch (err: unknown) {
      const error = err as TApiError;
      toast.error(error.message);
    }
  };

  const clearNotifications = () => {
    setNotifications([]);
    resetPagination();
  };

  useEffect(() => {
    if (userRole !== EUserRoles.PLATFORM_ADMIN) {
      const interval = window.setInterval(getUnreadNotoficationCount, 10000);
      getUnreadNotoficationCount();
      return () => {
        window.clearInterval(interval);
      };
    }
  }, [userRole]);

  return (
    <NotificationContext.Provider
      value={{
        notifications,
        unreadCount,
        deleteNotification,
        getNotofications,
        clearNotifications,
        markAsRead,
        hasMoreNotifications: hasMore,
        loading,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
