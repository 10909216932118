import classNames from "classnames";
import React from "react";

import * as styles from "./DocumentStatusBadge.css";

import { TChangeTrackerStatus, TDocument } from "@web/types/document";

interface DocumentStatusBadgeProps {
  document?: TDocument;
  className?: string;
}

export enum DocumentBadgeText {
  RESTORED = "Restored",
  DELETED = "Deleted",
}

function DocumentStatusBadge({ document, className }: DocumentStatusBadgeProps): React.ReactElement | null {
  if (document?.deletedAt) {
    return <span className={classNames(styles.deleted, className)}>{DocumentBadgeText.DELETED}</span>;
  } else if (document?.syncStatuses?.includes(TChangeTrackerStatus.RESTORED)) {
    return <span className={classNames(styles.restored, className)}>{DocumentBadgeText.RESTORED}</span>;
  }

  return null;
}

export default DocumentStatusBadge;
