import "components/App/App.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"components/App/App.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6WUYW/aMBCGv/dXWJomFW1GKSGUZL/GiZ3kOmNHjlOg0/77znYMDtAP1QCBbB+vn3vvLmsDxXlfZuTPEyEcxkGyc0VaKU6/cMP9Ug5GNBa0qkij5XRQ7qRmze/O6Enxinxr/cttY4A2uLPhebHN3c7AOAfVVSRzqwMzHah50QvoeluRlyz77tZH4LaPy79P64D24tEW913Vo8Q+GzzwXQZMQqcoWHEYEV8oK0xMDCnwzSark9s2/raI6Q7J9kvaiRIcOq8WIfOtE7oE5P5wTjpguJtPdOFDhKFStM6r/UJj6zV8mdCdI56nVbjWxYqTpZ534YJWlh5nulpLnigX/2sEIR0bIvCF6dhjWHLNzl8z6BFChxkhmYX3NOQ19Wkzy0VP47rWhgtDDeMwIUQRrLsjfZtGC+2ZNpg5cqa0n3WK82iED4GpbMJdEpSgtwD3ni/c3WXZ3dhkbV3WZTIk1Grn2Cw4mdEpDhpuOmt/4xmrR5xLZysW2ik0TDbPrn3ID4J4q3S4Xosgf5lLbMowjiaQXv9MfXKrJTe9TzR478EJkgDHw7wt2iIcnujYM+6bc4cR/itHCmK6mj1nP8n8WWfl6vEz5JGTaV22IaUPCoq7ud486Ig5Rr8L00oH0wPnQi1slRCcjdbsZ9yvNP3nZSuTiaImPvoW48wehSyfGnUa4qtNQ8A/KxfEB8oFAAA=\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var header = 'ri5y891';
export var headerBody = 'ri5y894';
export var headerIcons = 'ri5y895';
export var headerLogo = 'ri5y892';
export var orgImage = 'ri5y893';
export var profileMenu = 'ri5y898';
export var profileMenuIcon = 'ri5y899';
export var profileMenuItem = 'ri5y897';
export var profileMenuWrapper = 'ri5y896';
export var root = 'ri5y890';
export var userIcon = 'ri5y89b';
export var usersGroupIcon = 'ri5y89a';