import { TDBMetaBase, TPaginatedList } from "./api";
import { TDocument } from "./document";
import { TOrganisationBase } from "./organisation";

export type TLoginUserType = "user" | "platform_user";

export enum EUserRolesLabel {
  ORGANIZATION_USER = "User",
  ORGANIZATION_ADMIN = "Admin",
  PROJECT_REVIEWER = "Reviewer",
  PROJECT_COORDINATOR = "Coordinator",
  PLATFORM_ADMIN = "PlatformAdmin",
}

export enum EUserRoles {
  ORGANIZATION_USER = "ORGANIZATION_USER",
  ORGANIZATION_ADMIN = "ORGANIZATION_ADMIN",
  PROJECT_REVIEWER = "PROJECT_REVIEWER",
  PROJECT_COORDINATOR = "PROJECT_COORDINATOR",
  PLATFORM_ADMIN = "PLATFORM_ADMIN",
}

export type TProjectRole = {
  projectId: string;
  role: EUserRoles;
};

export type TUserBase = {
  id: string;
  externalId: string;
  firstName: string;
  lastName: string;
  email?: string;
  role: EUserRoles;
  organizationId: string;
  userProjectRole: TProjectRole[] | [];
  jobTitle: string | null;
  department: string | null;
};

export type TReviewerBase = {
  documentId: string;
  id: string;
  projectId: string;
  user: TUserBase;
  userId: string;
  document: TDocument;
};

export type TProjectUserBase = {
  id: string;
  userId: string;
  projectId: string;
  role: EUserRoles;
};

export type TUser = Readonly<TUserBase & TDBMetaBase & { organization: TOrganisationBase }>;
export type TProjectUser = TProjectUserBase & TDBMetaBase;
export type TUpdateUserCommand = TUserBase;
export type TUsersPaginatedList = Readonly<TPaginatedList<TUserBase>>;
export type TGetUsersCommand = { page: number; limit: number };
export type TReviewer = TReviewerBase & TDBMetaBase;
