import Head from "next/head";
import Image from "next/image";
import Link from "next/link";
import router from "next/router";
import { useSession } from "next-auth/react";
import React from "react";

import Notification from "../Notification";
import OutsideClickHandler from "../OutsideClickHandler/OutsideClickHandler";

import * as styles from "./App.css";

import Icon from "@web/components/Icon/Icon";
import { useProjectContext } from "@web/context/ProjectContext";
import { useSessionContext } from "@web/context/SessionContext";
import { useUserContext } from "@web/context/UserContext";
import useModal from "@web/hooks/useModal";
import { EUserRoles } from "@web/types/user";

type AppProps = {
  header?: React.ReactNode;
  children: React.ReactNode;
  showOrgLogo?: boolean;
};

function ProfileMenuItem() {
  const { user } = useUserContext();
  const { logout } = useSessionContext();
  const { isOpen, toggleModal } = useModal();

  return (
    <div className={styles.profileMenuWrapper}>
      <div className={styles.profileMenuItem} onClick={toggleModal}>
        {user?.firstName || user?.lastName ? (
          (`${user?.firstName || ""}`.charAt(0) + `${user?.lastName || ""}`.charAt(0)).toUpperCase()
        ) : (
          <Icon name="user" color="white" size={18} className={styles.userIcon} />
        )}
      </div>
      {isOpen && (
        <OutsideClickHandler onOutsideClick={toggleModal}>
          <ul className={styles.profileMenu}>
            {user?.role === EUserRoles.ORGANIZATION_ADMIN && (
              <li
                onClick={(e) => {
                  e.stopPropagation();
                  router.push("/projects/users");
                }}
              >
                <Icon name="userGroup" className={styles.usersGroupIcon} size={23} />
                Users & Roles
              </li>
            )}
            <li
              onClick={(e) => {
                e.stopPropagation();
                logout();
              }}
            >
              <Icon name="signin" className={styles.profileMenuIcon} />
              Logout
            </li>
          </ul>
        </OutsideClickHandler>
      )}
    </div>
  );
}

function App({ header = null, children, showOrgLogo = false }: AppProps): React.ReactElement {
  const { data: session } = useSession();
  const { user } = useSessionContext();
  const { project } = useProjectContext();
  return (
    <>
      <Head>
        <title>Deeligence {project ? `| ${project.name}` : ""}</title>
      </Head>
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Link href="/">
              <Image width="48" height="48" src="/deeligence.svg" alt="Deeligence Icon" />
            </Link>

            {showOrgLogo && user?.organization?.logo && (
              <Image
                className={styles.orgImage}
                width="48"
                height="48"
                src={user.organization.logo}
                alt="Deeligence Icon"
              />
            )}
          </div>
          <div className={styles.headerBody}>{header}</div>
          <div className={styles.headerIcons}>
            {session?.user.role !== EUserRoles.PLATFORM_ADMIN && <Notification />}
            <div>
              <ProfileMenuItem />
            </div>
          </div>
        </div>
        <div className="">{children}</div>
      </div>
    </>
  );
}

export default App;
