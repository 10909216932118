import { useRouter } from "next/router";
import { useSession } from "next-auth/react";
import React, { createContext, useContext, useEffect, useState } from "react";

import UnAuthorized from "@web/components/UnAuthorized/UnAuthorized";
import routes from "@web/constants/routes";
import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import { TProject, TProjectIndexType, TUserProjectRole } from "@web/types/project";
import { EUserRoles } from "@web/types/user";

type TC = {
  project?: TProject;
  getUserRole: () => EUserRoles;
  refreshProjectDetails: () => void;
  isCoordinator: boolean;
  updateIndexType: (indexType: TProjectIndexType) => void;
};

export const ProjectContext = createContext<TC>({
  project: undefined,
  getUserRole: () => EUserRoles.PROJECT_REVIEWER,
  refreshProjectDetails: () => undefined,
  isCoordinator: false,
  updateIndexType: () => undefined,
});

export const useProjectContext = (): TC => {
  return useContext(ProjectContext);
};

export const ProjectContextProvider = ({ children }: { children: React.ReactNode }) => {
  const router = useRouter();
  const { data } = useSession();
  const { error } = useToast();

  const [project, setProject] = useState<TProject>();
  const [isCoordinator, setIsCoordinator] = useState<boolean>(false);

  const userId = data?.user?.id;
  const id = router.query.id as string;
  const projectsRoute =
    routes["PROJECT_COORDINATOR"].includes(router.pathname) || routes["PROJECT_REVIEWER"].includes(router.pathname);
  const userRole = isCoordinator ? EUserRoles.PROJECT_COORDINATOR : EUserRoles.PROJECT_REVIEWER;

  const getProjectById = async () => {
    try {
      const response = await http.getProjectById(id);
      setProject(response);
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
        if (["id must be a UUID", "Project not found"].includes(err.message)) {
          setTimeout(() => {
            router.push("/projects");
          }, 4000);
        }
      }
    }
  };

  const getUserRole = (): EUserRoles => {
    const userInfo = project?.userProjectRole?.find((user) => user.userId === userId);
    return userInfo ? userInfo.role : EUserRoles.PROJECT_REVIEWER;
  };

  const refreshProjectDetails = () => {
    getProjectById();
  };

  const updateIndexType = (indexType: TProjectIndexType) => {
    project && setProject({ ...project, indexType });
  };

  useEffect(() => {
    if (projectsRoute && id) {
      getProjectById();
    }
    return () => setProject(undefined);
  }, [id]);

  useEffect(() => {
    if (userId && project?.userProjectRole && project.userProjectRole.length > 0) {
      const coordinators = project?.userProjectRole
        ?.filter((user: TUserProjectRole) => user.role === EUserRoles.PROJECT_COORDINATOR)
        .map((user: TUserProjectRole) => user.userId);

      if (coordinators?.includes(`${userId}`)) {
        setIsCoordinator(true);
      } else {
        setIsCoordinator(false);
      }
    }
  }, [userId, project?.id]);

  return (
    <ProjectContext.Provider value={{ project, getUserRole, refreshProjectDetails, isCoordinator, updateIndexType }}>
      {project?.isArchived ? (
        <UnAuthorized message="Project archived!" />
      ) : project && !routes[userRole].includes(router.pathname) ? (
        <UnAuthorized message="You are not authorized to view this page" />
      ) : (
        <>{children}</>
      )}
    </ProjectContext.Provider>
  );
};
