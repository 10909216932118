import { z } from "zod";

import { TTemplateFieldSchema } from "@web/types/template";

type Schema = z.ZodObject<z.ZodRawShape>;

function useTemplateFields(fields: TTemplateFieldSchema[] | null, formData?: { [key: string]: unknown }) {
  const initValues: { [key: string]: unknown } = {};

  let schema: Schema = z.object({});
  const obj: { [key: string]: unknown } = {};

  fields &&
    fields.map((field: TTemplateFieldSchema) => {
      let validation;
      const { type, key, isRequired } = field;
      const value = formData && formData[key];
      if (type === "free-text" || type === "select" || type === "yes-no") {
        validation = z.string();
        initValues[key] = value || "";
        if (isRequired) {
          validation = z.string().min(1, { message: " " });
        }
      }
      if (type === "multi-select") {
        validation = z.array(z.any());
        initValues[key] = value || [];
        if (isRequired) {
          validation = z.array(z.any()).min(1, { message: " " });
        }
      }
      if (type === "date") {
        initValues[key] = value ? new Date(value) : null;
        if (isRequired) {
          validation = z.date({
            required_error: "Select valid date",
            invalid_type_error: "Please select a valid date",
          });
        } else {
          validation = z.union([z.date(), z.null()]);
        }
      }
      obj[field.key] = validation;
      schema = z.object(obj);
    });

  return {
    initValues,
    schema,
    fields,
  };
}

export default useTemplateFields;
