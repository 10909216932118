import Modal from "../Modal";

import FormSuccess, { TFormSuccessProps } from "./FormSuccess";

type TFormSuccessModalProps = {
  isOpen: boolean;
} & TFormSuccessProps;

function FormSuccessModal({ isOpen, ...rest }: TFormSuccessModalProps) {
  return (
    <Modal isOpen={isOpen} align="center">
      <FormSuccess {...rest} />
    </Modal>
  );
}

export default FormSuccessModal;
