import * as styles from "./UnAuthorized.css";

import App from "@web/components/App/App";

function UnAuthorized({ message }: { message: string }) {
  return (
    <App>
      <div className={styles.root}>
        <h1>Oops!</h1>
        <div className={styles.subHeading}>{message}</div>
      </div>
    </App>
  );
}

export default UnAuthorized;
