import React, { useEffect, useRef } from "react";

import * as styles from "./Modal.css";

import cx from "@web/utils/cx";

export type TModalProps = {
  isOpen: boolean;
  children: React.ReactNode;
  align?: "top" | "center";
  className?: string;
  onOutsideClick?: () => void;
  closeModal?: () => void;
};

const Modal = ({
  isOpen,
  children,
  align = "top",
  className,
  onOutsideClick,
}: TModalProps): React.ReactElement | null => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!onOutsideClick) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onOutsideClick();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen, onOutsideClick]);

  if (!isOpen) return null;

  return (
    <div className={cx(styles, { modal: true, center: align === "center", top: align === "top" })}>
      <div ref={modalRef} className={[styles.modalContainer, className].join(" ")}>
        {children}
      </div>
    </div>
  );
};

export default Modal;
