import React from "react";
import { FieldError, useFormContext } from "react-hook-form";

import { useFormSchema } from "../Form/Form";

import { TextBox } from "./TextBox/TextBox";

export type TextFieldProps = {
  name: string;
  label: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
};

function TextField({
  name,
  label,
  className,
  placeholder,
  required,
  disabled = false,
}: TextFieldProps): React.ReactElement {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const schema = useFormSchema();
  const error = errors[name] as FieldError | undefined;
  const isRequired = required != null ? required : !schema.shape[name].isOptional;

  return (
    <TextBox
      className={className}
      label={label}
      disabled={disabled}
      required={isRequired}
      error={error?.message}
      placeholder={placeholder}
      formRegister={{ ...register(name) }}
    />
  );
}

export default TextField;
