import IcomoonReact from "icomoon-react";

import iconSet from "./selection.json";

export const iconNames = [
  "user",
  "date",
  "success",
  "document",
  "issue",
  "selectedDocument",
  "upload",
  "folder",
  "right",
  "down",
  "flag-empty",
  "flag-filled",
  "disabledFlag",
  "file",
  "search",
  "add",
  "cloud-download",
  "people",
  "selectedPeople",
  "loading",
  "alert",
  "questionMark",
  "userGroup",
  "signin",
  "close",
  "archive",
  "unarchive",
  "star",
  "check-mark",
  "right-arrow",
  "dashboard",
  "plus",
  "left-arrow",
  "template",
  "send",
  "check-circle",
  "filled-check-circle",
  "filled-check-circle-full",
  "arrow-up-square",
  "arrow-down-square",
  "white-user",
  "eye",
  "bin",
  "half-filled-circle",
  "refresh",
  "maximaize-circle",
  "delete-circle",
  "pending",
  "note-issue",
  "filled-cancel-circle",
  "bell",
  "x-mark",
  "question-document",
  "bell-on",
  "exclude-document",
  "include",
  "delete",
  "file-download",
  "user-add",
  "flat-send",
  "comment-empty",
  "comment-filled",
  "save",
  "checklist",
  "audit-log",
  "move",
  "ellipsis",
  "trash",
  "file-check",
  "sliders",
  "add-folder",
  "file-zip",
  "move-folder",
  "edit",
  "trash",
  "x-circle",
  "warning",
  "copy",
  "locate",
  "ai",
] as const;

export type IconName = (typeof iconNames)[number];

type IconProps = {
  color?: string;
  size?: number | string;
  name: IconName;
  className?: string;
  onClick?: React.MouseEventHandler<HTMLImageElement> | (() => void);
};

const Icon = ({ color = "", size = "28", name, className = "", onClick }: IconProps) => {
  return (
    <IcomoonReact
      data-testid={name}
      className={className}
      iconSet={iconSet}
      color={color}
      size={size}
      icon={name}
      onClick={onClick}
    />
  );
};

export default Icon;
