import { ChangeEventHandler, MouseEventHandler } from "react";

import * as styles from "./InlineCheckbox.css";

type TInlineCheckboxProps = {
  onClick?: MouseEventHandler<HTMLInputElement> | (() => void);
  defaultCheck?: boolean;
  disabled?: boolean;
  id?: string;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
};

function InlineCheckbox({ onClick, defaultCheck, disabled = false, id, checked, onChange }: TInlineCheckboxProps) {
  return (
    <label className={styles.root}>
      <input
        type="checkbox"
        onClick={onClick}
        defaultChecked={defaultCheck}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        id={id}
      />
    </label>
  );
}

export default InlineCheckbox;
