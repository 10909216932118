import "components/Evidences/Evidences.css.ts.vanilla.css!=!../../../node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"components/Evidences/Evidences.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA6VTy26DMBC85yss9dIeiCDkQZyvMXiBTY2NbOfVKv9eY/NI2lRKVXGB8ezOrHeY7/d2e9wk5HNGCEfTCnahpBRw3jmACaxkhBYaQ0kB0oLu4Iq1lCxbTymUUJqSlw1km+22Q0olbWTwAyhJFoEUIHsRDpNKN0yM6Amwqq1rF8cdJlBCVPdYsu41Dtp0Iq3CwcIJua0padg5KlwbZ203u87mYZqFn8ZqJg1aVJKG99Ipk3ieGgLMwMPGY4vUtziiwRwFWncnNXIO8oYSk59ynQQlWllm4TXJYg7V203J0jPH8cJ0DdMVOo9ZeyZxgHJWvFdaHSR3Nwvr7vGw0hx0lCtrVePriVEC+cQZlVZeaVjOgqerZfp9OenTy8mV4L9uJ/gfXWUdOvpYP+Fj+b+Q3AtuHid5fzAWy8sQFkpMywqIcrAnuNtq9rjeRz5MPFK3f/9pkn7B2FS+eAzyqj+Y0jEg10mQ+Zq+YtGfD/zh+1k/P25kOlJH0KVQp5vQfwGkrNj+KAQAAA==\"}!../../../node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var description = 'jjt9v76';
export var divider = 'jjt9v74';
export var expanded = 'jjt9v70';
export var heading = 'jjt9v75';
export var headingContainer = 'jjt9v77';
export var headingIconsContainer = 'jjt9v78';
export var hidden = 'jjt9v73';
export var icon = 'jjt9v72';
export var leftWraper = 'jjt9v71';
export var loaderContainer = 'jjt9v7a';
export var loadingWrap = 'jjt9v79';