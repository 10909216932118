import { useRouter } from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";

import { useToast } from "@web/hooks/useToast";
import { http } from "@web/services/withAuth";
import { TDocStatus, TDocument } from "@web/types/document";
import { TChecklist } from "@web/types/template";

type TC = {
  document?: TDocument;
  updateDocStatus: (status: TDocStatus) => void;
  documentChecklists: TChecklist[];
  getDocumentById: () => Promise<TDocument | undefined>;
  getDocumentChecklists: () => Promise<TChecklist[] | undefined>;
};

export const DocumentContext = createContext<TC>({
  document: undefined,
  updateDocStatus: () => null,
  documentChecklists: [],
  getDocumentById: async () => undefined,
  getDocumentChecklists: async () => [],
});

export const useDocumentContext = (): TC => {
  return useContext(DocumentContext);
};

export const DocumentContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [document, setDocument] = useState<TDocument>();
  const [documentChecklists, setDocumentChecklists] = useState<TChecklist[]>([]);
  const router = useRouter();
  const { error } = useToast();
  const documentId = router.query.documentid as string;

  const getDocumentById = async (): Promise<TDocument | undefined> => {
    try {
      const document = await http.getDocument(documentId);
      setDocument(document);
      return document;
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      }
    }
  };

  const updateDocStatus = (status: TDocStatus) => {
    if (document) {
      setDocument({ ...document, docStatus: status });
    }
  };

  const getDocumentChecklists = async () => {
    try {
      const checklists = await http.getSelectedChecklists(documentId);
      setDocumentChecklists(checklists);
      return checklists;
    } catch (err: unknown) {
      if (err instanceof Error) {
        error(err.message);
      }
    }
  };

  useEffect(() => {
    if (documentId) {
      getDocumentById();
      getDocumentChecklists();
    }
  }, [documentId]);

  return (
    <DocumentContext.Provider
      value={{ document, updateDocStatus, documentChecklists, getDocumentById, getDocumentChecklists }}
    >
      {children}
    </DocumentContext.Provider>
  );
};
