import { TDBMetaBase, TPaginatedList } from "./api";
import { TComment } from "./comment";
import { TDocType, TDocument } from "./document";
import { TReviewer, TUser, TUserBase } from "./user";

export type TCreateIssueCommand = {
  riskStatus: string;
  riskRating: string;
  issueCategory: string;
  issueTitle: string;
  issueDescription: string;
  recommendationTitle: string;
  recommendation: string;
  isDraft?: boolean;
  seniorReviewerId?: string;
  relatedDocuments?: string[];
  status?: EIssueStatus;
};

export type TIssueFormValues = {
  riskStatus: string;
  riskRating: string;
  issueCategory: string;
  issueTitle: string;
  issueDescription: string;
  recommendationTitle: string;
  recommendation: string;
  isDraft?: boolean;
  seniorReview?: TUser;
  relatedDocs?: TDocument[];
  assignSeniorReview?: boolean;
};

export type TIssueBase = {
  id: string;
  issueTitle: string;
  issueDescription: string;
  recommendationTitle: string;
  recommendation: string;
  documentId: string;
  status: EIssueStatus | null;
  riskRating: string;
  isDraft: boolean;
  issueCategory: string;
  issueNumber: number;
  riskStatus: string;
  seniorReviewerId: string | null;
  type: TDocType;
  subRows?: TIssue[];
  document?: TDocument;
  relatedDocuments?: { document: TDocument }[];
  seniorReviewer?: TUser;
  reviewers?: TReviewer[];
  user?: TUserBase;
  comments?: TComment[];
};

export enum EIssueStatus {
  NOTED = "NOTED",
  ACTION_REQUIRED = "ACTION_REQUIRED",
  RESOLVED = "RESOLVED",
  PENDING_APPROVAL = "PENDING_APPROVAL",
}

export type TIssuesTab = "All Issues" | "Raised by Me" | "Draft Issues";
export type TIssue = Readonly<TIssueBase & TDBMetaBase>;

export type TIssueSummarySubStat = {
  count: number;
  subStatus: EIssueStatus;
};

export type TIssueSummaryStat = {
  count: number;
  status: string;
  subStatuses: TIssueSummarySubStat[];
};

export type TIssueSummaryResponse = {
  total: number;
  stats: TIssueSummaryStat[];
};

export type TIssueStats = {
  stats: {
    [key: string]: {
      count: number;
      actionrequired: number;
      noted: number;
      resolved: number;
    };
  };
  total: number;
};

export type TIssuesCount = {
  total: number;
};

export type TIssuesPaginatedList = Readonly<TPaginatedList<TIssue>>;

export type TExportIssues = {
  category: string;
  categoryIssues: TIssue[];
};
