import { useState, useEffect } from "react";

import CommentBox from "../CommentBox";

import { http } from "@web/services/withAuth";
import { TComment } from "@web/types/comment";
import { TIssue } from "@web/types/issue";
import { TDeltaStatic } from "@web/types/rich-editor";

const IssueComments = ({
  issue: { id: issueId, issueTitle },
  onClose,
  isPlainBox = false,
  hideBackdrop = false,
}: {
  issue: TIssue;
  onClose(): void;
  isPlainBox?: boolean;
  hideBackdrop?: boolean;
}) => {
  const [comments, setComments] = useState<TComment[]>([]);
  useEffect(() => {
    loadComments();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      loadComments();
    }, 3000);

    return () => clearInterval(intervalId);
  }, [issueId]);

  const loadComments = async () => {
    const comments = await http.getIssueComments(issueId);
    setComments(comments);
  };

  const onPostComment = async (comment: TDeltaStatic) => {
    return http.postIssueComment(issueId, comment).then(loadComments);
  };

  return (
    <CommentBox
      isPlainBox={isPlainBox}
      title={issueTitle}
      comments={comments}
      onClose={onClose}
      onPostComment={onPostComment}
      hideBackdrop={hideBackdrop}
      preventImages={false}
    />
  );
};

export default IssueComments;
