import { ReactNode, useEffect, useRef } from "react";

type OutsideClickHandlerProps = {
  onOutsideClick: () => void;
  children: ReactNode;
};

const OutsideClickHandler = ({ onOutsideClick, children }: OutsideClickHandlerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const isMouseDownRef = useRef(false);

  useEffect(() => {
    const handleMouseDown = () => {
      isMouseDownRef.current = true;
    };

    const handleMouseUp = (event: MouseEvent) => {
      if (isMouseDownRef.current && containerRef.current && !containerRef.current.contains(event.target as Node)) {
        onOutsideClick();
      }
      isMouseDownRef.current = false;
    };

    document.addEventListener("mousedown", handleMouseDown);
    document.addEventListener("mouseup", handleMouseUp);

    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [onOutsideClick]);

  return <div ref={containerRef}>{children}</div>;
};

export default OutsideClickHandler;
