import type { AppProps } from "next/app";
import Head from "next/head";
import { SessionProvider } from "next-auth/react";

import Authorization from "@web/components/Authorization";
import { ChangeTrackerContextProvider } from "@web/context/ChangeTrackerContext";
import { DocumentTabsContextProvider } from "@web/context/DocumentTabsContext";
import { ExpandIndexTableContextProvider } from "@web/context/ExpandIndexTableContext";
import { IndexTableContextProvider } from "@web/context/IndexTableContext";
import { IndexTableSelectContextProvider } from "@web/context/IndexTableSelectContext";
import { IssueCountContextProvider } from "@web/context/IssueCountContext";
import { IssueModalContextProvider } from "@web/context/IssueModalContext";
import { NotificationContextProvider } from "@web/context/NotificationContext";
import { OtherDocumentsContextProvider } from "@web/context/OtherDocumentsContext";
import { ProjectContextProvider } from "@web/context/ProjectContext";
import { SessionContextProvider } from "@web/context/SessionContext";
import { TemplateContextProvider } from "@web/context/TemplateViewContext";
import { ToastContextProvider } from "@web/context/ToastContext";
import { UserContextProvider } from "@web/context/UserContext";
import { root } from "@web/styles/reset.css";

export default function App({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <>
      <Head>
        <title>Deeligence</title>
        <meta name="description" content="Simple, stress-free and smark workflow for due diligence" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/deeligence.svg" type="image/svg+xml" />
      </Head>
      <SessionProvider session={session}>
        <SessionContextProvider>
          <UserContextProvider>
            <NotificationContextProvider>
              <ToastContextProvider>
                <ProjectContextProvider>
                  <main className={root}>
                    <Authorization>
                      <TemplateContextProvider>
                        <IssueCountContextProvider>
                          <IssueModalContextProvider>
                            <DocumentTabsContextProvider>
                              <IndexTableContextProvider>
                                <ChangeTrackerContextProvider>
                                  <IndexTableSelectContextProvider>
                                    <OtherDocumentsContextProvider>
                                      <ExpandIndexTableContextProvider>
                                        <Component {...pageProps} />
                                      </ExpandIndexTableContextProvider>
                                    </OtherDocumentsContextProvider>
                                  </IndexTableSelectContextProvider>
                                </ChangeTrackerContextProvider>
                              </IndexTableContextProvider>
                            </DocumentTabsContextProvider>
                          </IssueModalContextProvider>
                        </IssueCountContextProvider>
                      </TemplateContextProvider>
                    </Authorization>
                  </main>
                </ProjectContextProvider>
              </ToastContextProvider>
            </NotificationContextProvider>
          </UserContextProvider>
        </SessionContextProvider>
      </SessionProvider>
    </>
  );
}
