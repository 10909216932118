import React from "react";
import { FieldError, useFormContext } from "react-hook-form";

import * as styles from "./CheckBox.css";

export type CheckBoxProps = {
  label: string;
  value: string;
  isChecked?: boolean;
  name: string;
  disabled?: boolean;
};

function CheckBox({ isChecked = false, label, value, name, disabled }: CheckBoxProps): React.ReactElement {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  const error = errors[name] as FieldError | undefined;

  return (
    <>
      <div className={styles.checkboxWrapper} key={value}>
        <input type="checkbox" id={name} defaultChecked={isChecked} {...register(name)} disabled={disabled} />
        <label className={styles.checkBoxLabel} htmlFor={name}>
          {label}
        </label>
      </div>
      {error != null ? <div className={styles.errors}>{error.message}</div> : null}
    </>
  );
}

export default CheckBox;
