import { signIn, signOut, useSession } from "next-auth/react";
import React, { createContext, useContext, useEffect, useState } from "react";

import { http } from "@web/services/withAuth";
import { TLogoutResponse } from "@web/types/api";
import { TFeatureFlagWithStatus, TFeatureFlags } from "@web/types/feature-flag";
import { TUser } from "@web/types/user";

type TC = {
  user?: TUser;
  featureFlagsWithStatus?: TFeatureFlagWithStatus[];
  clearUser: () => void;
  logout: () => void;
  isFeatureFlagEnabled: (tag: TFeatureFlags) => boolean;
  isFeatureFlagsLoaded: boolean;
};

export const SessionContext = createContext<TC>({
  user: undefined,
  featureFlagsWithStatus: undefined,
  isFeatureFlagEnabled: () => false,
  isFeatureFlagsLoaded: false,
  clearUser: () => null,
  logout: () => null,
});

export const useSessionContext = (): TC => {
  return useContext(SessionContext);
};

export const SessionContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { data: session } = useSession();
  const [user, setUser] = useState<TUser>();
  const [featureFlagsWithStatus, setFeatureFlagsWithStatus] = useState<TFeatureFlagWithStatus[]>([]);
  const [isFeatureFlagsLoaded, setIsFeatureFlagsLoaded] = useState<boolean>(false);
  const clearUser = () => setUser(undefined);

  const isFeatureFlagEnabled = (tag: string) => {
    return featureFlagsWithStatus?.some((flag) => flag.featureFlag.tag === tag && flag.isEnabled);
  };

  const logout = async () => {
    const resp = await fetch("/api/auth/logout");
    const data: TLogoutResponse = await resp.json();
    setFeatureFlagsWithStatus([]);
    await clearUser();
    await signOut();
    window.location.href = `${process.env.NEXT_PUBLIC_COGNITO_DOMAIN}/logout?client_id=${data.clientId}&logout_uri=${process.env.NEXT_PUBLIC_LOGOUT_REDIRECT_URL}`;
  };

  useEffect(() => {
    if (session?.error && session?.error.message === "RefreshAccessTokenError") {
      signIn("cognito");
    }
  }, [session?.error]);

  useEffect(() => {
    async function getUser() {
      http.getUser().then((user) => {
        setUser(user);
        user.organization &&
          http.getOrganizationFeatureFlags(user.organization?.id).then((featureFlags) => {
            setFeatureFlagsWithStatus(featureFlags);
            setIsFeatureFlagsLoaded(true);
          });
      });
    }
    if (!user) {
      getUser();
    }
  }, []);

  return (
    <SessionContext.Provider
      value={{ user, featureFlagsWithStatus, clearUser, logout, isFeatureFlagEnabled, isFeatureFlagsLoaded }}
    >
      {children}
    </SessionContext.Provider>
  );
};
